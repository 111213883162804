import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { showModal } from "../actions/user_actions";
import { Link, withRouter } from "react-router-dom";

class Notification extends Component {
  state = {
    notification: false
  };

  componentDidMount() {
    this.setState({ notification: this.props.notification });
  }

  changeRoutePath(e) {
    e.preventDefault();
    this.setState({ notification: false });
    this.props.history.push("/dashboard");
  }

  componentDidUpdate(prevProps) {
    if (this.props.notification !== prevProps.notification) {
      this.setState({ notification: true });
    }
  }

  render() {
    const { notification } = this.props;

    return (
      <li className="nav-item dropdown hidden-caret submenu">
        <a
          className="nav-link dropdown-toggle"
          href="# "
          id="notifDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <i className="fa fa-bell"></i>
          {this.state.notification && notification.length > 0 ? (
            <span className="notification">{notification.length}</span>
          ) : null}
        </a>
        <ul
          className="dropdown-menu notif-box animated fadeIn "
          aria-labelledby="notifDropdown"
          style={{ width: "520px" }}
        >
          {notification.length === 0 ? null : (
            <>
              <li>
                <div className="dropdown-title">
                  Tienes {notification.length} notificaciones nuevas
                </div>
              </li>
              {notification.map((item, i) => (
                <li key={i}>
                  <div
                    className="scroll-wrapper notif-scroll scrollbar-outer"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="notif-scroll scrollbar-outer scroll-content"
                      style={{
                        height: "auto",
                        marginBottom: "0px",
                        marginRight: "0px",
                        maxHeight: "256px"
                      }}
                    >
                      <div className="notif-center">
                        <Link
                          to="/dashboard"
                          onClick={e => this.changeRoutePath(e)}
                        >
                          <div className="notif-icon notif-primary">
                            <i className="fa fa-bell"></i>
                          </div>
                          <div className="notif-content">
                            <span className="block" style={{ fontSize: 14 }}>
                              {item.data}
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="scroll-element scroll-x">
                      <div className="scroll-element_outer">
                        <div className="scroll-element_size"></div>
                        <div className="scroll-element_track"></div>
                        <div
                          className="scroll-bar ui-draggable ui-draggable-handle"
                          style={{ width: "100px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="scroll-element scroll-y">
                      <div className="scroll-element_outer">
                        <div className="scroll-element_size"></div>
                        <div className="scroll-element_track"></div>
                        <div
                          className="scroll-bar ui-draggable ui-draggable-handle"
                          style={{ height: "100px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </>
          )}
        </ul>
      </li>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notification: state.reducer.notification2
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showModal }, dispatch);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Notification);
