import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/es";
import CrudButtons from "../../consts/CrudButtons";
import { saveBloodPressure } from "../../actions/emd_actions";
import Loading from "../Loading";

const validate = values => {
  const errors = {};

  if (values.high && isNaN(Number(values.high))) {
    errors.high = "Tiene que ser un número";
  } else if (values.high && Number(values.high) < 1) {
    errors.high = "Lo siento, valor incorrecto";
  }

  if (values.low && isNaN(Number(values.low))) {
    errors.low = "Tiene que ser un número";
  } else if (values.low && Number(values.low) < 1) {
    errors.low = "Lo siento, valor incorrecto";
  }
  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input className={className} {...input} type={type} />
        {touched && (error && <span>{error}</span>)}
      </div>
    </div>
  );
};

class BloodPressure extends Component {
  state = {
    bloodPressure: [],
    count: 0,
    form: false
  };

  componentDidMount() {
    const { data } = this.props;
    this.setState({
      bloodPressure: data
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.bloodPressure) {
      return { bloodPressure: props.data };
    }

    return null;
  }

  increment() {
    this.setState({ count: this.state.count + 3 });
  }

  decrement() {
    this.setState({ count: this.state.count - 3 });
  }

  render() {
    const { handleSubmit, user, dispatch, reset, loading } = this.props;
    const { bloodPressure } = this.state;

    const onFormSubmit = data => {
      data.user = user._id;

      dispatch(saveBloodPressure(data));
      this.setState({ form: false });
      reset("BloodPressure");
    };

    var finit = Math.round(bloodPressure && bloodPressure.length / 3);
    if (!Number.isInteger(bloodPressure && bloodPressure.length / 3))
      finit = finit + 1;

    const createTable = value => {
      let table = [];

      for (let i = 0; i < value; i++) {
        table.push(
          <li className="page-item" key={i}>
            <button
              style={{
                color: i * 3 === this.state.count ? "#ffffff" : "#19769F",
                backgroundColor: i * 3 === this.state.count ? "#19769F" : null
              }}
              className="page-link"
              onClick={() => this.setState({ count: i * 3 })}
            >
              {i + 1}
            </button>
          </li>
        );
      }
      return table;
    };

    return (
      <div className="col-md-6">
        <div className="card full-height">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <div className="card-title">Tensión arterial</div>
              <div className="ml-auto">
                <button
                  onClick={() => {
                    this.setState({ form: !this.state.form });
                    reset("BloodPressure");
                  }}
                  className="btn btn-icon btn-round btn-primary text-center"
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            {this.state.form && (
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label>Alta</label>
                    <Field
                      name={"high"}
                      className="form-control"
                      component={renderField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label>Baja</label>
                    <Field
                      name={"low"}
                      className="form-control"
                      component={renderField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="form-group">
                    <label>{/*Acción*/}</label>

                    <button
                      onClick={handleSubmit(onFormSubmit)}
                      className="btn btn-icon btn-round btn-primary text-center"
                    >
                      <i className="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {loading && <Loading />}
            {!loading && (
              <>
                <div className="separator-dashed"></div>
                {bloodPressure &&
                  bloodPressure
                    .sort(function(a, b) {
                      a = new Date(a.created_at);
                      b = new Date(b.created_at);
                      if (a > b) return -1;
                      if (a < b) return 1;
                      return 0;
                    })
                    .slice(this.state.count, this.state.count + 3)
                    .map((item, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex">
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                Alta
                              </h6>
                              <span className="text-muted">
                                {item.high.value}
                              </span>
                              <span className="text-warning pl-3"></span>
                            </div>
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                Baja
                              </h6>
                              <span className="text-muted">
                                {item.low.value}
                              </span>
                              <span className="text-warning pl-3"></span>
                            </div>
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                Fecha
                              </h6>
                              <span className="text-muted">
                                {moment(item.created_at).format("DD-MM-YYYY")}
                              </span>
                            </div>
                            <CrudButtons
                              data={item}
                              user_id={user._id}
                              clickHandler={"bloodPressure"}
                            />
                          </div>
                          <div className="separator-dashed"></div>
                        </div>
                      );
                    })}
                <br />
                <div className="align-content-center justify-content-center">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      {this.state.count > 0 ? (
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.decrement.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-left"></i>
                        </button>
                      ) : (
                        false
                      )}
                    </li>
                    {createTable(finit)}
                    {this.state.count + 3 < finit * 3 ? (
                      <li className="page-item">
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.increment.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-right"></i>
                        </button>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.reducer.loadingBloodPressure
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveBloodPressure }, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "BloodPressure",
    validate
  })
)(BloodPressure);
