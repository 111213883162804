import React, { Component } from "react";
import { Field, reduxForm, initialize as initializeForm } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Modal, Button } from "react-bootstrap";
import {
  fetchChangePassword,
  fetchPasswordConfirm,
  fetchForgetPassword
} from "../../actions/user_actions";

const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  children,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <label className="placeholder">
          <b>{placeholder}</b>
        </label>
        <div className="position-relative">
          <input
            className={className}
            {...input}
            type={type}
            placeholder={placeholder}
          />
          {children}
        </div>
        {touched && (error && <span>{error}</span>)}
      </div>
    </div>
  );
};

const validate = values => {
  const errors = {};
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values && values.email)
  ) {
    errors.email = "Dirección de correo electrónico no válida";
  }

  if (!values.newPassword) {
    errors.newPassword = "Obligatorio";
  } else if (values.newPassword.length < 6) {
    errors.newPassword = "Debe tener 6 caracteres o mas";
  }

  if (!values.rePassword) {
    errors.rePassword = "Obligatorio";
  } else if (values.rePassword.length < 6) {
    errors.rePassword = "Debe tener 6 caracteres o mas";
  } else if (values.rePassword !== values.newPassword) {
    errors.rePassword = "No coinciden la contraseña";
  }

  return errors;
};

const SendEmail = props => {
  const { handleSubmit, sendEmail } = props;
  return (
    <>
      <Modal.Body>
        <Field
          className="form-control"
          name="email"
          component={renderField}
          type="email"
          placeholder="Email"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit(sendEmail)}>
          Enviar
        </Button>
      </Modal.Footer>
    </>
  );
};

const InsertCode = props => {
  const { handleSubmit, insertCode } = props;
  return (
    <>
      <Modal.Body>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            color: "#19769f",
            fontWeight: "bold",
            padding: 10
          }}
        >
          {"Se ha enviado un código a su correo para recuperar la contraseña"}
        </div>

        <Field
          className="form-control"
          name="code"
          component={renderField}
          type="text"
          placeholder="Ingrese el código"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit(insertCode)}>
          Enviar
        </Button>
      </Modal.Footer>
    </>
  );
};
const NewPassword = props => {
  const {
    handleSubmit,
    newPassword,
    toggleShow,
    toggleShow2,
    hidden,
    hidden2
  } = props;
  return (
    <>
      <Modal.Body>
        <Field
          className="form-control"
          name="newPassword"
          component={renderField}
          type={hidden ? "password" : "text"}
          placeholder="Contraseña"
        >
          <div className="show-password">
            <button
              style={{
                position: "absolute",
                top: 0,
                right: 5,
                backgroundColor: "transparent",
                borderColor: "transparent",
                color: "#19769f"
              }}
              onClick={toggleShow}
              type="button"
            >
              <i className={hidden ? "far fa-eye" : "far fa-eye-slash"}></i>
            </button>
          </div>
        </Field>

        <Field
          className="form-control"
          name="rePassword"
          component={renderField}
          type={hidden2 ? "password" : "text"}
          placeholder="Repetir Contraseña"
        >
          <div className="show-password">
            <button
              style={{
                position: "absolute",
                top: 0,
                right: 5,
                backgroundColor: "transparent",
                borderColor: "transparent",
                color: "#19769f"
              }}
              onClick={toggleShow2}
              type="button"
            >
              <i className={hidden2 ? "far fa-eye" : "far fa-eye-slash"}></i>
            </button>
          </div>
        </Field>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit(newPassword)}>
          Enviar
        </Button>
      </Modal.Footer>
    </>
  );
};

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      option: 0,
      hidden: true,
      hidden2: true
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.insertCode = this.insertCode.bind(this);
    this.newPassword = this.newPassword.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.toggleShow2 = this.toggleShow2.bind(this);
  }

  componentDidMount() {
    const { initializeForm, user } = this.props;
    let init = {
      email: user.user.email
    };
    initializeForm("PasswordChange", init);
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  toggleShow2() {
    this.setState({ hidden2: !this.state.hidden2 });
  }

  sendEmail(data) {
    let values = {
      ForgetPassword: {
        email: data.email
      }
    };
    this.props.dispatch(fetchForgetPassword(values, true));
    this.setState({ option: 1 });
  }

  insertCode(data) {
    let values = {
      ForgetPasswordConfirm: {
        email: this.props.emailForget,
        code: data.code
      }
    };
    this.props.dispatch(fetchPasswordConfirm(values, true));
    this.setState({ option: 2 });
  }

  newPassword(data) {
    let values = {
      PasswordChange: {
        password: data.newPassword,
        rePassword: data.rePassword
      }
    };

    console.log(this.props.tokenNewPassword);

    this.props.dispatch(
      fetchChangePassword(values, this.props.tokenNewPassword, true)
    );
    this.handleClose();
    this.setState({ option: 1 });
  }

  RenderChildren() {
    switch (this.state.option) {
      case 0:
        return (
          <SendEmail
            handleSubmit={this.props.handleSubmit}
            sendEmail={this.sendEmail}
          />
        );
      case 1:
        return (
          <InsertCode
            handleSubmit={this.props.handleSubmit}
            insertCode={this.insertCode}
          />
        );
      case 2:
        return (
          <NewPassword
            hidden={this.state.hidden}
            hidden2={this.state.hidden2}
            toggleShow={this.toggleShow}
            toggleShow2={this.toggleShow2}
            handleSubmit={this.props.handleSubmit}
            newPassword={this.newPassword}
          />
        );

      default:
        return this.state.option;
    }
  }

  render() {
    const { show } = this.state;

    return (
      <>
        <li>
          <a onClick={this.handleShow} href="# ">
            <span className="link-collapse">Cambiar contraseña</span>
          </a>
        </li>
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Cambiar contraseña</Modal.Title>
          </Modal.Header>
          {this.RenderChildren()}
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    tokenNewPassword: state.reducer.tokenNewPassword,
    emailForget: state.reducer.emailForget
  };
};

const mapDispatchToProps = dispatch => {
  bindActionCreators({ fetchForgetPassword }, dispatch);
  return {
    initializeForm: (formName, values) =>
      dispatch(initializeForm(formName, values))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "PasswordChange",
    validate
  })
)(ChangePassword);
