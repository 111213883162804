import React, { Component } from "react";
import Maintainer from "./maintainer";

class SideBar extends Component {
  render() {
    return (
      <div id="menuBurger" className="sidebar sidebar-style-2 overflow-auto ">
        <Maintainer />
      </div>
    );
  }
}

export default SideBar;
