import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

moment.locale("es");

const MenuItem = ({ text }) => {
  return (
    <div className="col-md-3" key={text._id}>
      <div className="title col-md-3">{text.name.es}</div>
    </div>
  );
};

export const Menu = list =>
  list.map((el, i) => {
    return <MenuItem text={el} />;
  });

const Arrow = ({ text }) => {
  return <div>{text}</div>;
};
Arrow.propTypes = {
  text: PropTypes.string
};

export const ArrowLeft = Arrow({ text: "<" });
export const ArrowRight = Arrow({ text: ">" });

class Profile extends Component {
  state = {
    alignCenter: true,
    clickWhenDrag: false,
    dragging: true,
    hideArrows: true,
    hideSingleArrow: true,
    files: this.props.user.user.picture,
    itemsCount:
      this.props.user &&
      this.props.user.medical &&
      this.props.user.medical.specialties &&
      this.props.user.medical.specialties.length > 0
        ? this.props.user.medical.specialties
        : [],
    selected:
      this.props.user &&
      this.props.user.medical &&
      this.props.user.medical.specialties &&
      this.props.user.medical.specialties.length > 0
        ? this.props.user.medical.specialties[0]
        : [],

    translate: 0,
    transition: 0.4,
    wheel: true
  };

  constructor(props) {
    super(props);

    this.menu = null;
    if (
      this.props.user &&
      this.props.user.medical &&
      this.props.user.medical.specialties
    )
      this.menuItems = Menu(
        this.props.user.medical.specialties.slice(
          0,
          this.props.user.medical.specialties.length
        ),
        this.state.selected
      );

    this.getImage();
  }

  componentDidUpdate(prevProps, prevState) {
    const { alignCenter } = prevState;
    const { alignCenter: alignCenterNew } = this.state;
    if (alignCenter !== alignCenterNew) {
      this.menu.setInitial();
    }
  }

  getImage() {
    axios
      .get(this.props.user.user.picture)
      .then(result => {
        this.setState({ files: this.props.user.user.picture });
      })
      .catch(error => {
        this.setState({ files: this.props.user.user.picture });
      });
  }

  render() {
    const { user } = this.props;
    /*  const {
      alignCenter,
      clickWhenDrag,
      hideArrows,
      dragging,
      hideSingleArrow,
      selected,
      translate,
      transition,
      wheel
    } = this.state;

    const menu = this.menuItems;*/

    return (
      <>
        <div
          className="panel-header bg-primary-gradientuser"
          style={{ height: 165 }}
        >
          <div className="page-inner py-5">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div>
                <h2 className="text-white pb-2 fw-bold">
                  {user && user.user.gender === "Femenino"
                    ? "Bienvenida"
                    : "Bienvenido"}{" "}
                  {user && user.user.name} {user && user.user.lastname}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row mt--2">
            <div className="col-md-6">
              <div className="card full-height">
                <div className="card-body">
                  <div className="card-title" style={{ fontSize: "1.2rem" }}>
                    Datos personales
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <div className="avatar-sm float-left mr-2">
                        {this.state.files === "" ? (
                          <FontAwesomeIcon icon={faUserMd} size="3x" />
                        ) : (
                          <img
                            src={this.state.files}
                            alt="imagen-doctor"
                            className="avatar-img rounded-circle"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-category">Nombres</div>
                      <div className="title">{user && user.user.name}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-category">Apellidos</div>
                      <div className="title">{user && user.user.lastname}</div>
                    </div>
                    <div className="col-auto">
                      <div className="card-category">Género</div>
                      <div className="title">
                        {user && user.user.gender === "Femenino"
                          ? "Femenino"
                          : "Masculino"}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-category">Fecha de Nacimiento</div>
                      <div className="title">
                        {user &&
                          moment(user.user.birthdate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-category">Email</div>
                      <div className="title">{user && user.user.email}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-category">
                        Número de colegio de médicos
                      </div>
                      <div className="title">
                        {user && user.medical.medical_number.length === 0 ? (
                          <div className="text-center">
                            <Link to="/editprofile">
                              <i
                                className="fas fa-plus-circle"
                                style={{ color: "#19769F" }}
                              ></i>
                            </Link>
                          </div>
                        ) : (
                          user.medical.medical_number
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-category">
                        Número de ministerio de salud
                      </div>
                      <div className="title">
                        {user && !user.medical.governmental_number ? (
                          <div className="text-center">
                            <Link to="/editprofile">
                              <i
                                className="fas fa-plus-circle"
                                style={{ color: "#19769F" }}
                              ></i>
                            </Link>
                          </div>
                        ) : (
                          user.medical.governmental_number
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card full-height">
                <div className="card-body">
                  <div className="card-title" style={{ fontSize: "1.2rem" }}>
                    Datos profesionales
                  </div>
                  <div className="card-category text-center">
                    Estudios Realizados{" "}
                    {user.medical &&
                    user.medical.documents &&
                    user.medical.documents.length >= 3 ? (
                      <div />
                    ) : (
                      <Link to="/editprofile">
                        <i
                          className="fas fa-plus-circle"
                          style={{ color: "#19769F" }}
                        ></i>
                      </Link>
                    )}
                  </div>
                  <div className="row text-center">
                    {user.medical &&
                      user.medical.documents &&
                      user.medical.documents.map((item, i) => (
                        <div className="col-md-3" key={i}>
                          <div className="card-category">{item.grade}</div>
                          <div className="title">{item.title}</div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row mt--2">
            <div className="col-md-6">
              <div className="card full-height">
                <div className="card-body">
                  <div className="card-title" style={{ fontSize: "1.2rem" }}>
                    Especialidades {"  "}
                    <Link to="/editprofile">
                      <i
                        className="fas fa-plus-circle"
                        style={{ color: "#19769F" }}
                      ></i>
                    </Link>
                  </div>
                  <div className="card-category text-center">
                    {/*<>
                                    <ScrollMenu
                                      data={menu}
                                      arrowLeft={ArrowLeft}
                                      arrowRight={ArrowRight}
                                      hideArrows={hideArrows}
                                      hideSingleArrow={hideSingleArrow}
                                      transition={+transition}
                                      onUpdate={this.onUpdate}
                                      onSelect={this.onSelect}
                                      selected={selected}
                                      translate={translate}
                                      alignCenter={alignCenter}
                                      dragging={dragging}
                                      clickWhenDrag={clickWhenDrag}
                                      wheel={wheel}
                                    />
                                  </>*/}
                  </div>
                  <div className="row text-center">
                    {user.medical &&
                      user.medical.specialties &&
                      user.medical.specialties.map((item, i) => (
                        <div className="col-md-3" key={i}>
                          <div className="title">{item.name.es}</div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card full-height">
                <div className="card-body">
                  <div className="card-title" style={{ fontSize: "1.2rem" }}>
                    Datos laborales
                  </div>
                  <div className="row">
                    {user &&
                      user.medical &&
                      user.medical.offices.length > 0 &&
                      user.medical.offices.map((item, i) => (
                        <div className="col-md-3" key={i}>
                          <div className="card-category">
                            Consultorio #{i + 1}
                          </div>
                          <div className="title">{item.name}</div>
                          {/*<div className="card-category">
                                  Teléfono Consultorio
                                </div>*/}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};

export default compose(connect(mapStateToProps))(Profile);
