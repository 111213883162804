import React from "react";
import { connect } from "react-redux";

function UserGreet(props) {
  return (
    <div className="panel-header bg-primary-gradientuser">
      <div className="page-inner py-5">
        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
          <div>
            <h2 className="text-white pb-2 fw-bold">
              Bienvenido {props.user && props.user.user.name}{" "}
              {props.user && props.user.user.lastname}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};

export default connect(mapStateToProps)(UserGreet);
