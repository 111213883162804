import React from "react";

export default class Search extends React.Component {
  componentDidMount() {
    const { data, onUpdateData } = this.props;
    onUpdateData(data);
  }

  handleInputChange = event => {
    const query = event.currentTarget.value.toLowerCase();

    const { data, filterData, onUpdateData } = this.props;

    let filteredData;

    if (!query) {
      filteredData = [];
    } else {
      filteredData = data.filter(
        data =>
          filterData(query, data.user.name) ||
          filterData(query, data.user.identificator) ||
          filterData(query, data.user.dni) ||
          filterData(query, data.user.lastname)
      );
    }

    onUpdateData(filteredData);
  };

  render() {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            id="basic-addon1"
            style={{ backgroundColor: "#19769F", color: "#ffffff" }}
          >
            <i className="fa fa-search search-icon"></i>
          </span>
        </div>
        <input
          className="form-control"
          placeholder="Buscar..."
          onChange={this.handleInputChange}
        />
      </div>
    );
  }
}
