import React, { Component } from "react";
import PersonalData from "./PersonalData";
import MainCareer from "./maincareer";
import MainSpecialty from "./mainspecialty";

class Editprofile extends Component {
  render() {
    return (
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <h4
              className="page-title"
              style={{
                fontFamily: "Poppins",
                color: "#19769f",
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              Datos personales
            </h4>
            <PersonalData />
          </div>
          <div className="col-md-12">
            <h4
              className="page-title"
              style={{
                fontFamily: "Poppins",
                color: "#19769f",
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              Datos profesionales
            </h4>
          </div>
          <div className="col-md-6 career">
            <MainCareer title="Estudios" />
          </div>
          <div className="col-md-6 speciality">
            <MainSpecialty />
          </div>
        </div>
      </div>
    );
  }
}

export default Editprofile;
