import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import ReactLoading from "react-loading";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-google-places-autocomplete";
import { addOffice, deleteOffice } from "../../../actions/doctor_actions";
import { Field, reduxForm } from "redux-form";
import { Modal } from "react-bootstrap";

const Example = () => (
  <ReactLoading
    type={"spinningBubbles"}
    color={"#19769F"}
    height={"15%"}
    width={"15%"}
  />
);

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = "Obligatorio";
  } else if (values.name.length < 3) {
    errors.name = "Debe tener mínimo 3 caracteres";
  }

  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input
          className={className}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        {touched && (error && <span>{error}</span>)}
      </div>
    </div>
  );
};

class MainWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      delete: false,
      item: "",
      send: true
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ modal: false });
  }

  render() {
    const { handleSubmit, user } = this.props;

    const onFormSubmit = data => {
      let values = {
        office: {
          name: data.name,
          address: this.state.address,
          location: {
            accuracy: 65,
            altitude: 874.729736328125,
            altitudeAccuracy: 10,
            heading: -1,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            speed: -1
          }
        }
      };
      this.props.addOffice(values);
      this.handleClose();
      this.props.reset();
    };

    return (
      <>
        <div className="card">
          <div className="card-header">
            <button
              className="btn"
              style={{
                backgroundColor: "#19769F",
                color: "#ffffff",
                float: "right"
              }}
              onClick={() => this.setState({ modal: true })}
            >
              Agregar
            </button>
            <div className="card-title">
              <h4
                style={{
                  fontFamily: "Poppins",
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Consultorio
              </h4>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <table
                id="add-row"
                className="display table table-striped table-hover"
              >
                <thead>
                  <tr>
                    <th>Nombre de Consultorio</th>
                    <th>Dirección</th>
                    <th>Estado</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {user.medical &&
                    user.medical.offices.length > 0 &&
                    user.medical.offices.map((item, i) => (
                      <tr key={i}>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                        <td>
                          {item.status === "unverificated"
                            ? "Por verificar"
                            : item.status === "rejected"
                            ? "Rechazado"
                            : "Verificado"}
                        </td>
                        <td>
                          <div className="form-button-action">
                            {/*<button
                              type="button"
                              data-target="#addRowModal"
                              data-toggle="tooltip"
                              title=""
                              className="btn btn-link btn-primary btn-lg"
                              data-original-title="Editar"
                            >
                              <i className="fa fa-edit"></i>
                            </button>*/}
                            <button
                              className="btn btn-link btn-danger"
                              onClick={() =>
                                this.setState({ delete: true, item: item })
                              }
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <Modal show={this.state.modal} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4
                  style={{
                    color: "#19769f",
                    fontWeight: "bold"
                  }}
                >
                  Nuevo consultorio
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card-body">
                <div className="form-group">
                  <label>Nombre de consultorio</label>
                  <Field
                    className="form-control"
                    name="name"
                    component={renderField}
                    type="text"
                    placeholder="Nombre"
                  />
                </div>

                <div className="form-group">
                  <label>Ubicación</label>
                  <div className="select2-input">
                    <GooglePlacesAutocomplete
                      loader={<Example />}
                      renderInput={props => (
                        <input className="form-control" {...props} />
                      )}
                      onSelect={({ description }) =>
                        geocodeByAddress(description)
                          .then(results => getLatLng(results[0]))
                          .then(({ lat, lng }) =>
                            this.setState({
                              address: description,
                              longitude: lng,
                              latitude: lat,
                              send: false
                            })
                          )
                      }
                      renderSuggestions={(
                        active,
                        suggestions,
                        onSelectSuggestion
                      ) => (
                        <div>
                          {suggestions.map((suggestion, i) => (
                            <div
                              key={i}
                              style={{ marginTop: 5 }}
                              className="form-control"
                              onClick={event =>
                                onSelectSuggestion(suggestion, event)
                              }
                            >
                              {suggestion.description}
                            </div>
                          ))}
                        </div>
                      )}
                      autocompletionRequest={{
                        bounds: [{ lat: 50, lng: 50 }, { lat: 100, lng: 100 }]
                      }}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button
                  className="btn"
                  disabled={this.state.send ? true : false}
                  style={{ backgroundColor: "#19769F" }}
                  onClick={handleSubmit(onFormSubmit)}
                >
                  Guardar
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.delete}>
            <Modal.Header>
              <Modal.Title>
                <h4
                  style={{
                    color: "#19769f",
                    fontWeight: "bold"
                  }}
                >
                  Eliminar
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              ¿Seguro que deseas eliminar el consultorio{" "}
              {this.state.item && this.state.item.name}? <br /> Recuerda que
              esta acción no se puede deshacer y se borrara toda la información
              de atencion y citas.
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button
                  className="btn btn-sm"
                  style={{ backgroundColor: "#19769F" }}
                  onClick={() => this.setState({ delete: false, item: "" })}
                >
                  Cancelar
                </button>
              </div>
              <div>
                <button
                  className="btn btn-sm"
                  style={{ backgroundColor: "#19769F" }}
                  onClick={() => {
                    this.props.deleteOffice(this.state.item);

                    this.setState({ delete: false, item: "" });
                  }}
                >
                  Aceptar
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addOffice, deleteOffice }, dispatch);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "MainWork",
    validate
  })
)(MainWork);
