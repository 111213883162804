import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Notification from "./Notification";
import { showModal } from "../actions/user_actions";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false
    };
  }

  addingClass() {
    this.setState(prevState => ({
      hidden: !prevState.hidden
    }));

    if (this.state.hidden) {
      document.getElementById("menuBurger").className =
        "sidebar sidebar-style-2 ";
    } else {
      document.getElementById("menuBurger").className =
        "sidebar sidebar-style-2  menuEnabled";
    }
  }

  render() {
    const { modal, message } = this.props;
    const handleClose = () => this.props.showModal(false);
    return (
      <div className="main-header fixed-top">
        <div className="logo-header" data-background-color="blue">
          <Link to="/dashboard" className="logo">
            <img
              src="../assets/img/logo.png"
              alt="navbar brand"
              className="navbar-brand"
            />
          </Link>
          <button
            className="navbar-toggler sidenav-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="collapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.addingClass.bind(this)}
          >
            <span className="navbar-toggler-icon">
              <i className="icon-menu"></i>
            </span>
          </button>
          <button className="topbar-toggler more toggled">
            <i className="icon-options-vertical"></i>
          </button>
          <div className="nav-toggle">
            <button className="btn btn-toggle toggle-sidebar">
              <i className="icon-menu"></i>
            </button>
          </div>
        </div>
        <nav
          className="navbar navbar-header navbar-expand-lg"
          data-background-color="blue2"
        >
          <div className="container-fluid">
            <div className="collapse" id="search-nav">
              {/*<form className="navbar-left navbar-form nav-search mr-md-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button type="submit" className="btn btn-search pr-1">
                      <i className="fa fa-search search-icon"></i>
                    </button>
                  </div>
                  <input type="text" placeholder="Buscar ..." className="form-control" />
                </div>
              </form>*/}
            </div>
            <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
              <Notification />
              <li className="nav-item toggle-nav-search hidden-caret">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#search-nav"
                  role="button"
                  aria-expanded="false"
                  aria-controls="search-nav"
                >
                  <i className="fa fa-search"></i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <Modal show={modal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Alerta
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                backgroundColor: "#19769F",
                color: "#ffffff"
              }}
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    modal: state.reducer.modal,
    message: state.reducer.message
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
