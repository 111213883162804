import { TRANSTATION_LIST } from "../actions/transaction_actions";

const initialState = {
  data: [],
  success: false
};

export function transaction(state = initialState, action) {
  switch (action.type) {
    case TRANSTATION_LIST:
      return {
        ...state,
        data: action.data,
        success: true
      };

    default:
      return state;
  }
}
