import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  deleteAllergy,
  verificateAllergy,
  deletePathology,
  verificatePathology,
  verificateOrDeleteSurgical,
  verificateOrDeleteHabit,
  verificateOrDeleteFamily,
  verificateOrDeleteBloodPressure,
  verificateOrDeleteIMC
} from "../actions/emd_actions";

class CrudButtons extends Component {
  render() {
    const { data, clickHandler, user_id, position } = this.props;

    return (
      <div style={{ marginRight: 20 }}>
        {data.status === "verificated" ? (
          <button
            type="button"
            disabled={true}
            style={{ backgroundColor: "#33d034" }}
            className="btn btn-icon btn-round btn-primary"
          >
            <i className="fas fa-check-double"></i>
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              if (clickHandler === "allergy") {
                this.props.verificateAllergy(data, user_id);
              } else if (clickHandler === "pathology") {
                this.props.verificatePathology(data, user_id);
              } else if (clickHandler === "surgical") {
                this.props.verificateOrDeleteSurgical(
                  data,
                  user_id,
                  "/surgical-history/verificate",
                  "verificate"
                );
              } else if (clickHandler === "habit") {
                this.props.verificateOrDeleteHabit(
                  data,
                  user_id,
                  "/habit/verificate",
                  "verificate"
                );
              } else if (clickHandler === "family") {
                this.props.verificateOrDeleteFamily(
                  data,
                  user_id,
                  "/family-history/verificate",
                  "verificate"
                );
              } else if (clickHandler === "bloodPressure") {
                this.props.verificateOrDeleteBloodPressure(
                  data,
                  user_id,
                  "/medical-measurement/verificate-blood-pressure",
                  "verificate"
                );
              } else if (clickHandler === "imc") {
                this.props.verificateOrDeleteIMC(
                  data,
                  user_id,
                  position,
                  "/medical-measurement/verificate-imc",
                  "verificate"
                );
              }
            }}
            className="btn btn-icon btn-round btn-primary"
          >
            <i className="fas fa-check"></i>
          </button>
        )}
        {/*<button
          type="button"
          onClick={() => {
            if (clickHandler === "allergy") {
              this.props.deleteAllergy(data, user_id);
            } else if (clickHandler === "pathology") {
              this.props.deletePathology(data, user_id);
            } else if (clickHandler === "surgical") {
              this.props.verificateOrDeleteSurgical(
                data,
                user_id,
                "/surgical-history/delete",
                "delete"
              );
            } else if (clickHandler === "habit") {
              this.props.verificateOrDeleteHabit(
                data,
                user_id,
                "/habit/delete",
                "delete"
              );
            } else if (clickHandler === "family") {
              this.props.verificateOrDeleteFamily(
                data,
                user_id,
                "/family-history/delete",
                "delete"
              );
            } else if (clickHandler === "bloodPressure") {
              this.props.verificateOrDeleteBloodPressure(
                data,
                user_id,
                "/medical-measurement/delete-blood-pressure",
                "delete"
              );
            } else if (clickHandler === "imc") {
              this.props.verificateOrDeleteIMC(
                data,
                user_id,
                position,
                "/medical-measurement/delete-imc",
                "delete"
              );
            }
          }}
          className="btn btn-icon btn-round btn-primary"
        >
          <i className="fas fa-trash"></i>
        </button>*/}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteAllergy,
      verificateAllergy,
      verificateOrDeleteSurgical,
      deletePathology,
      verificatePathology,
      verificateOrDeleteHabit,
      verificateOrDeleteFamily,
      verificateOrDeleteBloodPressure,
      verificateOrDeleteIMC
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(CrudButtons);
