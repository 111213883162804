import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import PatientInfo from "../consultation/PatientInfo";
import PathologyHistories from "../consultation/PathologyHistories";
import SurgicalHistory from "../consultation/SurgicalHistory";
import Habits from "../consultation/Habits";
import FamilyHistories from "../consultation/FamilyHistories";
import BloodPressure from "../consultation/BloodPressure";
import IMC from "../consultation/IMC";
import Alergies from "../consultation/Alergies";
import moment from "moment";
import ModalPhysicalExam from "../modals/ModalPhysicalExam";
import { pdfConsultation } from "../../actions/appointment_actions";
// import history from "../../store/browserHistory";

import "moment/locale/es";
moment.locale("es");

class DetailEMD extends Component {
  state = {
    fisical_exam: [],
    count: 0,
    emdPatient: this.props.emdPatient,
    selectEMD: this.props.selectEMD
  };

  increment() {
    this.setState({ count: this.state.count + 5 });
  }

  decrement() {
    this.setState({ count: this.state.count - 5 });
  }

  /*componentDidUpdate(prevProps) {
    if (this.props.emdPatient !== prevProps.emdPatient) {
      console.log("cambie");
      this.setState({
        emdPatient: this.props.emdPatient
      });
    }
  }*/

  static getDerivedStateFromProps(props, state) {
    if (props.emdPatient !== state.emdPatient) {
      return { emdPatient: props.emdPatient };
    }
    if (props.selectEMD !== state.selectEMD) {
      return { selectEMD: props.selectEMD };
    }
    return null;
  }

  render() {
    //  const { selectEMD } = this.props;
    const { emdPatient, selectEMD } = this.state;

    var finit = Math.round(
      selectEMD && selectEMD.appointments && selectEMD.appointments.length / 5
    );

    const createTable = value => {
      let table = [];

      for (let i = 0; i < value; i++) {
        table.push(
          <li className="page-item" key={i}>
            <button
              style={{
                color: i * 5 === this.state.count ? "#ffffff" : "#19769F",
                backgroundColor: i * 5 === this.state.count ? "#19769F" : null
              }}
              className="page-link"
              onClick={() => this.setState({ count: i * 5 })}
            >
              {i + 1}
            </button>
          </li>
        );
      }
      return table;
    };

    return (
      <>
        <div className="page-inner">
          <div className="col-sm-6 col-lg-3" style={{ marginLeft: "auto" }}>
            <div className="card p-3">
              <div className="d-flex align-items-center">
                <span className="stamp stamp-md bg-primary mr-3">
                  <i className="fas fa-notes-medical"></i>
                </span>
                <div>
                  <h5 className="mb-1">
                    <b>EMD Nº</b>
                  </h5>
                  <small className="text-muted">
                    {emdPatient.identificator}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <PatientInfo data={emdPatient} />
          <div className="row">
            <IMC
              user={emdPatient}
              data={emdPatient.Health.MedicalMeasurement}
            />
            <BloodPressure
              user={emdPatient}
              data={
                emdPatient &&
                emdPatient.Health &&
                emdPatient.Health.MedicalMeasurement &&
                emdPatient.Health.MedicalMeasurement.blood_pressures
              }
            />
          </div>
          <div className="row">
            <Alergies
              user={emdPatient}
              data={emdPatient.Health.MedicalRecord.Alergies}
            />

            <Habits
              user={emdPatient}
              data={emdPatient.Health.MedicalRecord.Habits}
            />
          </div>
          <div className="row">
            <PathologyHistories
              user={emdPatient}
              data={emdPatient.Health.MedicalRecord.PathologyHistories}
            />
            <SurgicalHistory
              user={emdPatient}
              data={emdPatient.Health.MedicalRecord.SurgicalHistories}
            />
          </div>
          <div className="row">
            <FamilyHistories
              user={emdPatient}
              data={emdPatient.Health.MedicalRecord.FamilyHistories}
            />
          </div>
        </div>
        <div className="page-navs bg-white">
          <div className="nav-scroller">
            <div className="nav nav-tabs nav-line nav-color-secondary d-flex align-items-center justify-contents-center w-100">
              <Link to="#" className="nav-link active show" data-toggle="tab">
                Historial Médico
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-12" style={{ marginTop: "2rem" }}>
          <div className="card full-height">
            <div className="card-header">
              <div className="card-title">Información de consultas</div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table
                  id="add-row"
                  className="display table table-striped table-hover"
                >
                  <thead>
                    <tr>
                      <th>Fecha de la consulta</th>
                      <th>Motivo</th>
                      <th>Diagnóstico</th>
                      <th>Medicamentos</th>
                      <th>Observación</th>
                      <th>Examen físico</th>
                      <th>Pdf</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectEMD &&
                      selectEMD.appointments &&
                      selectEMD.appointments
                        .sort(function(a, b) {
                          a = new Date(a.created_at);
                          b = new Date(b.created_at);
                          if (a > b) return -1;
                          if (a < b) return 1;
                          return 0;
                        })
                        .slice(this.state.count, this.state.count + 5)
                        .map((item, i) => (
                          <tr key={i}>
                            <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                            <td>
                              {item.patients.consultation &&
                              item.patients.consultation.reason ? (
                                item.patients.consultation.reason
                              ) : (
                                <>{"Vacío"}</>
                              )}
                            </td>
                            <td>
                              {item.patients.consultation &&
                              item.patients.consultation.diagnostics ? (
                                item.patients.consultation.diagnostics.map(
                                  data => {
                                    return data.detail;
                                  }
                                )
                              ) : (
                                <>{"Vacío"}</>
                              )}
                            </td>
                            <td>
                              {item.patients.consultation &&
                              item.patients.consultation.medicaments ? (
                                item.patients.consultation.medicaments.map(
                                  data => {
                                    return data.detail;
                                  }
                                )
                              ) : (
                                <>{"Vacío"}</>
                              )}
                            </td>
                            <td>
                              {item.patients.consultation &&
                              item.patients.consultation.observation ? (
                                item.patients.consultation.observation
                              ) : (
                                <>{"Vacío"}</>
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() =>
                                  this.setState({
                                    fisical_exam:
                                      item.patients.consultation &&
                                      item.patients.consultation.fisical_exam
                                        .items
                                  })
                                }
                              >
                                Ver
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  console.log({
                                    appointment_id: item._id,
                                    user_id: selectEMD.user._id
                                  });
                                  this.props.pdfConsultation(
                                    {
                                      appointment_id: item._id,
                                      user_id: selectEMD.user._id
                                    },
                                    true
                                  );
                                }}
                              >
                                Ver pdf
                              </button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <ModalPhysicalExam data={this.state.fisical_exam} />
                <div className="align-content-center justify-content-center">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      {this.state.count > 0 ? (
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.decrement.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-left"></i>
                        </button>
                      ) : (
                        false
                      )}
                    </li>
                    {createTable(finit)}
                    {this.state.count + 5 < finit * 5 ? (
                      <li className="page-item">
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.increment.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-right"></i>
                        </button>
                      </li>
                    ) : (
                      true
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    emdPatient: state.reducer.appointment,
    selectEMD: state.reducer.selectEMD
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ pdfConsultation }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailEMD);
