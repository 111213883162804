import React, { Component } from "react";
import ImageLogin from "../img/ImageLogin";

class LoginRegister extends Component {
  render() {
    return (
      <div className="login">
        <div className="wrapper wrapper-login wrapper-login-full p-0">
          <div className="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center">
            <div style={{ width: "100%", height: "100%" }}>
              <ImageLogin />
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default LoginRegister;
