import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { listConfirmed } from "../../../actions/appointment_actions";

class SessionId extends Component {
  render() {
    return (
      <div data-tut="reactour__scroll--hidden">
        <ul className="nav nav-primary">
          <li className="nav-item" id="consultation">
            <Link className="link" to="/doctorconsultation">
              <i className="fas fa-medkit"></i>
              <p>Consultas</p>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ listConfirmed }, dispatch);

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  ),
  reduxForm({
    form: "SessionId"
  })
)(SessionId);
