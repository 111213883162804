import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {addDocument, deleteDocument} from "../../../actions/doctor_actions";
import {Field, reduxForm, initialize as initializeForm} from "redux-form";
import {Modal} from "react-bootstrap";
import FileBase64 from "react-file-base64";
import {showModal} from "../../../actions/user_actions";

import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const type = [
  {id: 1, name: "Profesión"},
  {id: 2, name: "Post grado"},
  {id: 3, name: "Maestría"},
];

const validate = (values) => {
  const errors = {};

  if (!values.title || values.title === "") {
    errors.title = "Obligatorio";
  } else if (values.title.length < 3) {
    errors.title = "Debe tener mínimo 3 caracteres";
  }

  if (!values.institute || values.institute === "") {
    errors.institute = "Obligatorio";
  } else if (values.institute.length < 3) {
    errors.institute = "Debe tener mínimo 3 caracteres";
  }

  if (!values.country || values.country === "") {
    errors.country = "Obligatorio";
  } else if (values.country.length < 3) {
    errors.country = "Debe tener mínimo 3 caracteres";
  }

  if (!values.date || values.date === "") {
    errors.date = "Obligatorio";
  }

  return errors;
};

const renderFieldSelect = ({
  input,
  name,
  className,
  options,
  meta: {touched, error},
}) => {
  return (
    <div>
      <div>
        <select className={className} {...input}>
          {options.map((item, i) => (
            <option value={item.name} key={i}>
              {item.name}
            </option>
          ))}
        </select>

        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  max,
  meta: {touched, error},
}) => {
  return (
    <div>
      <div>
        <input
          className={className}
          {...input}
          type={type}
          placeholder={placeholder}
          max={max}
        />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

class MainCareer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: false,
      modal: false,
      send: true,
      delete: false,
      item: "",
      type: type,
      documents:
        this.props.user.medical && this.props.user.medical.documents
          ? this.props.user.medical.documents
          : [],
    };
    this.handleClose = this.handleClose.bind(this);
  }
  getFiles(files) {
    var base64 = files.base64;
    var strImage = base64.split(",")[1];

    let search = files.name.split("").reverse().join("").indexOf(".");
    let result = files.name.slice(-search);
    this.setState({files: strImage, extends: result});
  }

  handleClose() {
    this.setState({modal: false});
  }

  static getDerivedStateFromProps(props, state) {
    const {user, typeDocuments} = props;
    console.log("entre en  getderivedstatedgro", typeDocuments);
    console.log("type", type);
    let typeDocumentNew = [...type];
    user.medical.documents.map((prof) => {
      const test = typeDocumentNew.find(
        (element) => prof.grade === element.name
      );
      var i = typeDocumentNew.indexOf(test);
      typeDocumentNew.splice(i, 1);

      return prof;
    });

    console.log("typeDocumentNew", typeDocumentNew);

    if (user.medical.documents !== state.documents) {
      console.log("entro en user.medical.documents !== state.documents");
      return {documents: user.medical.documents};
    }
    if (typeDocumentNew.length !== type.length) {
      console.log("typeDocumentNew.length !== type.length");
      const result = typeDocumentNew.filter((word) => word.id);

      console.log("reuslt", result);
      return {type: result};
    } else if (typeDocumentNew.findIndex((word) => word.id) >= 0) {
      console.log(
        "entre en typeDocumentNew.filterIndex((word) => word.id) >= 0"
      );
      const result = typeDocumentNew.filter((word) => word.id);

      return {type: result};
    }
    console.log("entro aca");
    return null;
  }

  render() {
    const {handleSubmit, title, user, reset, typeDocuments} = this.props;

    console.log("tpesdoscos", typeDocuments);
    const onFormSubmit = (data) => {
      let values = {
        document: {
          grade: !data.type ? typeDocuments[0].name : data.type,
          title: data.title,
          date: data.date,
          institute: data.institute,
          country: data.country,
        },
        certificate_64: {
          ext: this.state.files ? this.state.extends : "",
          base64: this.state.files ? this.state.files : "",
        },
      };

      this.props.dispatch(addDocument(values));
      this.handleClose();
      reset();
    };

    return (
      <div className="card">
        <div className="card-header">
          {user.medical &&
          user.medical.documents &&
          user.medical.documents.length >= 3 ? null : (
            <button
              className="btn"
              style={{
                backgroundColor: "#19769F",
                color: "#ffffff",
                float: "right",
              }}
              onClick={() => this.setState({modal: true})}
            >
              Agregar
            </button>
          )}

          <h4
            style={{
              fontFamily: "Poppins",
              color: "#19769f",
              fontWeight: "bold",
            }}
          >
            {title}
          </h4>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <table
              id="add-row"
              className="display table table-striped table-hover"
            >
              <thead>
                <tr>
                  <th>Título</th>
                  <th>Grado</th>
                  <th>Fecha</th>
                  <th>Estado</th>
                  <th style={{width: "10%"}}>Acción</th>
                </tr>
              </thead>
              <tbody>
                {this.state.documents.map((item, i) => (
                  <tr key={i}>
                    <td>{item.title}</td>
                    <td>{item.grade}</td>
                    <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                    <td>
                      {item.status === "unverificated"
                        ? "Por verificar"
                        : "Verificado"}
                    </td>
                    <td>
                      <div className="form-button-action">
                        {/*<button
                      type="button"
                      data-target="#addRowModal"
                      data-toggle="tooltip"
                      title=""
                      className="btn btn-link btn-primary btn-lg"
                      data-original-title="Editar"
                    >
                      <i className="fa fa-edit"></i>
                    </button>*/}
                        <button
                          className="btn btn-link btn-danger"
                          onClick={() =>
                            this.setState({delete: true, item: item})
                          }
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal size="lg" show={this.state.modal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold",
                }}
              >
                Nueva profesión
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Tipo</label>
                    <div className="select2-input">
                      <Field
                        name="type"
                        component={renderFieldSelect}
                        className="form-control"
                        options={this.state.type}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nombre</label>
                    <div className="select2-input">
                      <Field
                        name="title"
                        className="form-control"
                        component={renderField}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Instituto/Universidad</label>
                    <div className="select2-input">
                      <Field
                        name="institute"
                        className="form-control"
                        component={renderField}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>País</label>
                    <div className="select2-input">
                      <Field
                        name="country"
                        className="form-control"
                        component={renderField}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Fecha</label>
                    <div className="select2-input">
                      <Field
                        name="date"
                        className="form-control"
                        component={renderField}
                        type="date"
                        max={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Título</label>
                    <FileBase64
                      className="form-control-file"
                      onDone={this.getFiles.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn"
                style={{backgroundColor: "#19769F"}}
                onClick={handleSubmit(onFormSubmit)}
              >
                Guardar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.delete}>
          <Modal.Header>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold",
                }}
              >
                Eliminar
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            ¿Seguro que deseas eliminar esta profesión{" "}
            {this.state.item && this.state.item.title}?
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn btn-sm"
                style={{backgroundColor: "#19769F"}}
                onClick={() => this.setState({delete: false, item: ""})}
              >
                Cancelar
              </button>
            </div>
            <div>
              <button
                className="btn btn-sm"
                style={{backgroundColor: "#19769F"}}
                onClick={() => {
                  type.push({name: this.state.item.grade});
                  this.props.dispatch(deleteDocument(this.state.item));
                  this.setState({delete: false, item: ""});
                }}
              >
                Aceptar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    typeDocuments: state.reducer.typeDocuments,
  };
};

const mapDispatchToProps = (dispatch) => {
  bindActionCreators({addDocument, deleteDocument, showModal}, dispatch);
  return {
    initializeForm: (formName, values) =>
      dispatch(initializeForm(formName, values)),
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "MainCareer",
    validate,
  })
)(MainCareer);
