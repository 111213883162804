import React, { Component } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import PatientForm from "./patientForm";
import AppointmentInfo from "./Addappoinment";
import { addAppoinment } from "../../../actions/appointment_actions";

const validate = values => {
  const errors = {};

  if (!values.clinic) {
    errors.clinic = "Obligatorio";
  }

  if (!values.turn) {
    errors.turn = "Obligatorio";
  }

  if (!values.hour) {
    errors.hour = "Obligatorio";
  }

  if (!values.birthdate) {
    errors.birthdate = "Obligatorio";
  }

  if (!values.reason) {
    errors.reason = "Obligatorio";
  }

  var reg = /^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ.-]+/;

  if (!values.name) {
    errors.name = "Obligatorio";
  } else if (values.name.length > 10) {
    errors.name = "Debe tener 10 caracteres o menos";
  } else if (values.name.length < 3) {
    errors.name = "Debe tener 3 caracteres o mas";
  } else if (!reg.test(values.name)) {
    errors.name = "Caracteres no validos";
  }

  if (!values.lastname) {
    errors.lastname = "Obligatorio";
  } else if (values.lastname.length > 15) {
    errors.lastname = "Debe tener 15 caracteres o menos";
  } else if (values.lastname.length < 3) {
    errors.lastname = "Debe tener 3 caracteres o mas";
  } else if (!reg.test(values.lastname)) {
    errors.name = "Caracteres no validos";
  }

  if (parseInt(values.phone && values.phone.charAt(0)) === 0) {
    errors.phone = "El 0 no esta permitido al inicio del número";
  } else if (!/^([0-9])*$/.test(values.phone)) {
    errors.phone = "Debe ser un número";
  }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Dirección de correo electrónico no válida";
  }

  if (!values.dni) {
    errors.dni = "Obligatorio y debe ser un número";
  } else if (values.dni.length > 10) {
    errors.dni = "Debe tener máximo 10 caracteres";
  } else if (values.dni.length < 4) {
    errors.dni = "Debe tener mínimo 4 caracteres";
  }
  return errors;
};

class NewAppointment extends Component {
  render() {
    const { handleSubmit, data, listInsurance } = this.props;

    const onFormSubmit = values => {
      var insurance =
        values.insurance &&
        listInsurance.insurances_list[0][values.insurance - 1];

      if (values.insurance === "Ninguno") {
        insurance = "";
      } else {
      }

      let appointment = {
        appointment: {
          schedule_id: data.schedule,
          hour_id: values.turn,
          date: data.date
        },
        patient: {
          type_appointment: values.reason,
          hour: values.hour
        },
        user: {
          dni: `${values.nationality}-${values.dni}`,
          birthdate: values.birthdate,
          name: values.name,
          lastname: values.lastname,
          phones: values.phone
            ? [
                {
                  country_code: "+58",
                  number: values.phone
                }
              ]
            : [],
          emails: values.email
            ? [
                {
                  email: values.email
                }
              ]
            : [],
          gender: values.gender,
          insurances: [
            {
              carrier: insurance && insurance.name
            }
          ]
        }
      };
      this.props.dispatch(addAppoinment(appointment));
      this.props.reset();
    };

    return (
      <>
        <div className="page-inner">
          <div className="row" style={{ justifyContent: "center" }}>
            <h4
              className="page-title"
              style={{
                fontFamily: "Poppins",
                color: "#19769f",
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              Agendar cita
            </h4>
          </div>
          <div className="row">
            <div className="col-md-6">
              <PatientForm />
            </div>
            <div className="col-md-6">
              <AppointmentInfo />
            </div>
          </div>
        </div>
        <br />
        <div className="page-navs bg-white">
          <div className="nav-scroller">
            <div className="nav nav-tabs nav-line nav-color-secondary d-flex align-items-center justify-contents-center w-100">
              <div className="modal-footer no-bd">
                <button
                  type="button"
                  id="addRowButton"
                  style={{ backgroundColor: "#19769F", fontColor: "#fff" }}
                  onClick={handleSubmit(onFormSubmit)}
                  data-dismiss="modal"
                  className="btn"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    data: state.reducer.data,
    listInsurance: state.reducer.listInsurance
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addAppoinment }, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "AddAppoinment",
    validate
  })
)(NewAppointment);
