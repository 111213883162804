import React from "react";
import { Multiselect } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";

export const renderMultiselect = ({ input, data, valueField, textField }) => (
  <Multiselect
    {...input}
    onBlur={() => input.onBlur()}
    value={input.value || []} // requires value to be an array
    data={data}
    valueField={valueField}
    textField={textField}
  />
);

export const renderFieldSelect = ({
  input,
  name,
  className,
  type,
  options,
  children,
  disabled,
  label,
  marginTop,
  onClick,
  meta: { touched, error }
}) => {
  return (
    <div style={{ marginTop: marginTop }}>
      <label>{label}</label>
      <select
        className={className}
        {...input}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </select>

      <div style={{ textAlign: "center", paddingTop: 6, color: "#c91717" }}>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

export const renderField = ({
  input,
  name,
  className,
  placeholder,
  type,
  disabled,
  label,
  min,
  max,
  style,
  meta: { touched, error }
}) => {
  return (
    <div>
      <label>{label}</label>
      <input
        className={className}
        {...input}
        disabled={disabled}
        type={type}
        min={min}
        max={max}
        placeholder={placeholder}
      />
      <div style={{ textAlign: "center", paddingTop: 6, color: "#c63333" }}>
        {touched && error && <span>{error}</span>}
      </div>
      <br />
    </div>
  );
};
