import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/es";
import AutocompleteComponent from "./AutocompleteComponent";
import { savePathology } from "../../actions/emd_actions";
import CrudButtons from "../../consts/CrudButtons";
import Loading from "../Loading";

moment.locale("es");

const validate = values => {
  const errors = {};
  if (
    values.date &&
    moment(values.date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")
  ) {
    errors.date = "Fecha no válida";
  }

  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  max,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input className={className} {...input} type={type} max={max} />
        {touched && (error && <span>{error}</span>)}
      </div>
    </div>
  );
};

class PathologyHistories extends Component {
  state = {
    count: 0,
    form: false
  };

  increment() {
    this.setState({ count: this.state.count + 3 });
  }

  decrement() {
    this.setState({ count: this.state.count - 3 });
  }

  render() {
    const { data, handleSubmit, user, dispatch, reset, loading } = this.props;

    const onFormSubmit = data => {
      let values = {
        user: user._id,
        date: `${data.date}-01`
      };

      dispatch(savePathology(values, "save"));
      this.setState({ form: false });
      reset("Pathology");
    };

    let finit = Math.round(data && data.length / 3);
    if (!Number.isInteger(data && data.length / 3)) finit = finit + 1;

    const createTable = value => {
      let table = [];

      for (let i = 0; i < value; i++) {
        table.push(
          <li className="page-item" key={i}>
            <button
              style={{
                color: i * 3 === this.state.count ? "#ffffff" : "#19769F",
                backgroundColor: i * 3 === this.state.count ? "#19769F" : null
              }}
              className="page-link"
              onClick={() => this.setState({ count: i * 3 })}
            >
              {i + 1}
            </button>
          </li>
        );
      }
      return table;
    };

    return (
      <>
        <div className="col-md-6">
          <div className="card full-height">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="card-title">Antecedentes Patológicos</div>
                <div className="ml-auto">
                  <button
                    onClick={() => {
                      this.setState({ form: !this.state.form });
                      reset("Pathology");
                    }}
                    className="btn btn-icon btn-round btn-primary text-center"
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              {this.state.form && (
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <div className="select2-input">
                        <label>Nombre de la Patología</label>
                        <AutocompleteComponent />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <div className="select2-input">
                        <label>Fecha Aproximada</label>
                        <Field
                          name="date"
                          className="form-control"
                          component={renderField}
                          type="month"
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 ">
                    <div className="form-group">
                      <div className="select2-input">
                        <label>{/*Acción*/}</label>

                        <button
                          onClick={handleSubmit(onFormSubmit)}
                          className="btn btn-icon btn-round btn-primary text-center"
                        >
                          <i className="fas fa-check"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loading && <Loading />}
              {!loading && (
                <>
                  <div className="separator-dashed"></div>
                  {data &&
                    data
                      .sort(function(a, b) {
                        a = new Date(a.created_at);
                        b = new Date(b.created_at);
                        if (a > b) return -1;
                        if (a < b) return 1;
                        return 0;
                      })
                      .slice(this.state.count, this.state.count + 3)
                      .map((item, i) => {
                        return (
                          <div key={i}>
                            <div className="d-flex">
                              <div className="flex-1 ml-3 pt-1">
                                <h6
                                  className="text-uppercase fw-bold mb-1"
                                  style={{ fontSize: ".65rem" }}
                                >
                                  Nombre de la patología
                                </h6>
                                <span className="text-muted">
                                  {item.MedicalHistory.history.name.es}
                                </span>
                                <span className="text-warning pl-3"></span>
                              </div>
                              <div className="flex-1 ml-3 pt-1">
                                <h6
                                  className="text-uppercase fw-bold mb-1"
                                  style={{ fontSize: ".65rem" }}
                                >
                                  Fecha aproximada
                                </h6>
                                <span className="text-muted">
                                  {moment(item.MedicalHistory.date).format(
                                    "MM-YYYY"
                                  )}
                                </span>
                              </div>
                              <CrudButtons
                                data={item}
                                user_id={user._id}
                                clickHandler={"pathology"}
                              />
                            </div>
                            <div className="separator-dashed"></div>
                          </div>
                        );
                      })}
                  <br />
                  <div className="align-content-center justify-content-center">
                    <ul className="pagination justify-content-center">
                      <li className="page-item">
                        {this.state.count > 0 ? (
                          <button
                            style={{ color: "#19769F" }}
                            onClick={this.decrement.bind(this)}
                            className="page-link"
                          >
                            <i className="fas fa-angle-double-left"></i>
                          </button>
                        ) : (
                          false
                        )}
                      </li>
                      {createTable(finit)}
                      {this.state.count + 3 < finit * 3 ? (
                        <li className="page-item">
                          <button
                            style={{ color: "#19769F" }}
                            onClick={this.increment.bind(this)}
                            className="page-link"
                          >
                            <i className="fas fa-angle-double-right"></i>
                          </button>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.reducer.loadingPathology
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ savePathology }, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "Pathology",
    validate
  })
)(PathologyHistories);
