import React, { Component } from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import AppView from "./components";
import "./App.css";
import { positions, Provider as ProviderAlert } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ProviderAlert template={AlertTemplate} {...options}>
          <AppView />
        </ProviderAlert>
      </Provider>
    );
  }
}

export default App;
