import React from "react";
import { /*Field,*/ reduxForm } from "redux-form";
//import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { securityLogin } from "../../actions/user_actions";
//import Logo from "../../img/iconsGradient.png";
import classNames from "classnames";

import PinField from "react-pin-field";

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = "Obligatorio";
  } else if (values.password.length !== 4) {
    errors.password = "Debe tener 4 caracteres ";
  } else if (!/^[a-zA-ZñÑ10-9]+$/.test(values.password)) {
    errors.password = "Caracteres no validos";
  }

  return errors;
};

/*const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          className={className}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};*/

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      user: {},
      demoCompleted: false,
      code: "",
      completed: false
    };
  }

  setDemoCompleted() {
    this.setState({ demoCompleted: true });
  }

  setCode(value) {
    this.setState({ code: value });
  }

  setCompleted() {
    this.setState({ completed: true });
  }

  render() {
    const { handleSubmit } = this.props;

    const onFormSubmit = () => {
      let body = {
        password: this.state.code
      };
      this.props.securityLogin(body);
    };

    return (
      <div className="login-aside  d-flex align-items-center justify-content-center bg-white my-5">
        <div className="container container-login container-transparent animated fadeIn ">
          <h4 className="text-center">
            Introduzca su clave de acceso para ingresar al módulo
          </h4>
          <div className="w-50 m-auto  p-2 ">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              {/*<div className="form-group">
                <Field
                  className="form-control"
                  name="password"
                  component={renderField}
                  type="password"
                  placeholder="Clave"
                />
              </div>*/}
              <div className="container-a">
                <PinField
                  type="password"
                  length={4}
                  className={classNames("field-a", {
                    "field-a-complete": this.state.demoCompleted
                  })}
                  onChange={data => this.setCode(data)}
                  onComplete={() => this.setDemoCompleted()}
                  format={k => k.toUpperCase()}
                  autoFocus
                />
              </div>
              <div
                className="login-account m-auto "
                style={{ marginBottom: 15 }}
              >
                <span className="msg">
                  ¿Es Nuevo Usuario?,¿Olvido su clave de acceso?
                </span>

                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => this.props.clickHandler(true)}
                >
                  <span className="msg" style={{ color: "#19759f" }}>
                    Haz click aqui
                  </span>
                </button>
              </div>
              <div className="mt-2">
                <button
                  className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
                  type="submit"
                >
                  Validar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ securityLogin }, dispatch);

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  reduxForm({
    form: "LoginSecurity",
    validate
  })
)(Login);
