import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ChangePassword from "../modals/Modal_ChangePassword";

import { bindActionCreators } from "redux";
import { onLogout } from "../../actions/user_actions";
import ModalLoading from "../modals/Modal_Loading";
class User extends Component {
  render() {
    const { user } = this.props;

    return (
      <div className="user" data-tut="reactour__iso">
        <div className="info">
          <a
            data-toggle="collapse"
            href="#collapseExample"
            aria-expanded="true"
          >
            <span style={{ flexDirection: "column" }}>
              <span>
                {user && user.user.name} {user && user.user.lastname}
              </span>
              <span>{user && user.user.email}</span>
              <span>
                <span className="user-level">Usuario</span>
                <span className="caret"></span>
              </span>
            </span>
          </a>
          <div className="clearfix"></div>
          <div className="collapse in show" id="collapseExample">
            <ul className="nav">
              <li>
                <Link to="/profile">
                  <span className="link-collapse">Mi Perfil</span>
                </Link>
              </li>
              <li>
                <Link to="/editprofile">
                  <span className="link-collapse">Editar Perfil</span>
                </Link>
              </li>
              <li>
                <Link to="/configuration">
                  <span className="link-collapse">Editar Perfil Médico</span>
                </Link>
              </li>
              <ChangePassword />
              <li>
                <button
                  className="btn p-0"
                  style={{
                    background: "white"
                  }}
                  onClick={() => {
                    this.props.onLogout();
                  }}
                >
                  <span className="link-collapse" style={{ color: "#777" }}>
                    Cerrar Sesión
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <ModalLoading />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onLogout }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);
