import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, initialize as initializeForm } from "redux-form";
import { compose, bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getFormValues } from "redux-form";
// import UserGreet from "../../consts/UserGreet";
import { Modal } from "react-bootstrap";
import PatientInfo from "./PatientInfo";
import PathologyHistories from "./PathologyHistories";
import SurgicalHistory from "./SurgicalHistory";
import FamilyHistories from "./FamilyHistories";
import IMC from "./IMC";
import Alergies from "./Alergies";
import BloodPressure from "./BloodPressure";
import Habits from "./Habits";
import Observations from "./Observations";
import PhysicalExam from "./PhysicalExam";
import AppointmentReason from "./AppointmentReason";

import { setConsultation } from "../../actions/appointment_actions";
import { showModal } from "../../actions/user_actions";

class PatientEntry extends Component {
  state = {
    appointmentUser: this.props.appointment,
    check: false,
    send: false,
    data: []
  };

  componentDidUpdate(prevProps) {
    if (this.props.appointment !== prevProps.appointment) {
      this.setState({
        appointmentUser: this.props.appointment
      });
    }
  }

  componentDidMount() {
    const { appointmentUser } = this.state;

    const initData = {
      year2:
        appointmentUser.Health &&
        appointmentUser.Health.MedicalRecord &&
        appointmentUser.Health.MedicalRecord.Alergies &&
        appointmentUser.Health.MedicalRecord.Alergies.yearTest
    };
    this.props.initializeForm("Alergies", initData);
  }

  render() {
    const { appointmentUser } = this.state;
    const {
      handleSubmit,
      formulary,
      history,
      patient,
      showNot,
      appointmentReason,
      physicalExam,
      observation
    } = this.props;

    const onTest = data => {
      this.setState({ data: data });

      if (!observation.medicaltreatment || !observation.observations) {
        this.setState({ check: true });
      } else {
        onFormSubmit(data);
      }
    };

    const onFormSubmit = data => {
      var online = window.navigator.onLine;

      let values = {
        appointment_id: patient.appointment_id,
        patient_id: patient.patient_id,
        consultation: {
          reason: appointmentReason.reason ? appointmentReason.reason : "",
          observation: observation.observations ? observation.observations : "",
          fisical_exam: {
            _id: formulary && formulary.formulary && formulary.formulary._id,
            items:
              formulary &&
              formulary.formulary &&
              formulary.formulary.items.map(test => {
                let datos = {
                  _id: test._id,
                  title: {
                    es: test.title.es
                  },
                  value: physicalExam[test.title.es]
                    ? physicalExam[test.title.es]
                    : ""
                };

                return datos;
              }),
            type_formulary: formulary.formulary.type_formulary
          },
          diagnostics: [
            {
              detail: observation.diagnosis ? observation.diagnosis : ""
            }
          ],
          plan: observation.plan ? observation.plan : "",
          disease: observation.disease ? observation.disease : "",
          medicaments: [
            {
              detail: observation.medicaltreatment
                ? observation.medicaltreatment
                : ""
            }
          ]
        }
      };

      if (online) {
        this.props.dispatch(setConsultation(values, history));
      } else {
        this.props.dispatch(
          showModal({
            open: true,
            message:
              "Requiere de internet para realizar esta acción, NO refresque la pagina espere a tener internet y envía la información"
          })
        );
      }
    };

    return (
      <>
        <div className="page-inner">
          <div className="page-header">
            <h4 className="page-title">
              {!showNot ? "Ingreso de pacientes" : "Expediente médico"}
            </h4>
          </div>
          <div className="col-sm-6 col-lg-3" style={{ marginLeft: "auto" }}>
            <div className="card p-3">
              <div className="d-flex align-items-center">
                <span className="stamp stamp-md bg-primary mr-3">
                  <i className="fas fa-notes-medical"></i>
                </span>
                <div>
                  <h5 className="mb-1">
                    <b>EMD Nº</b>
                  </h5>
                  <small className="text-muted">
                    {appointmentUser && appointmentUser.identificator}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <PatientInfo data={appointmentUser} />

          <div className="row">
            <AppointmentReason />
            <Observations title={"Enfermedad Actual"} name={"disease"} />
          </div>

          <div className="row">
            <IMC
              user={appointmentUser}
              data={
                appointmentUser &&
                appointmentUser.Health &&
                appointmentUser.Health.MedicalMeasurement &&
                appointmentUser.Health.MedicalMeasurement
              }
            />

            <BloodPressure
              user={appointmentUser}
              data={
                appointmentUser &&
                appointmentUser.Health &&
                appointmentUser.Health.MedicalMeasurement &&
                appointmentUser.Health.MedicalMeasurement.blood_pressures
              }
            />
          </div>

          <div className="row">
            <Alergies
              user={appointmentUser}
              data={
                appointmentUser &&
                appointmentUser.Health &&
                appointmentUser.Health.MedicalRecord &&
                appointmentUser.Health.MedicalRecord.Alergies
              }
            />
            <Habits
              user={appointmentUser}
              data={
                appointmentUser &&
                appointmentUser.Health &&
                appointmentUser.Health.MedicalRecord &&
                appointmentUser.Health.MedicalRecord.Habits
              }
            />
          </div>

          <div className="row">
            <PathologyHistories
              user={appointmentUser}
              data={
                appointmentUser &&
                appointmentUser.Health &&
                appointmentUser.Health.MedicalRecord &&
                appointmentUser.Health.MedicalRecord.PathologyHistories
              }
            />
            <SurgicalHistory
              user={appointmentUser}
              data={
                appointmentUser &&
                appointmentUser.Health &&
                appointmentUser.Health.MedicalRecord &&
                appointmentUser.Health.MedicalRecord.SurgicalHistories
              }
            />
          </div>
          <div className="row">
            <FamilyHistories
              user={appointmentUser}
              data={
                appointmentUser &&
                appointmentUser.Health &&
                appointmentUser.Health.MedicalRecord &&
                appointmentUser.Health.MedicalRecord.FamilyHistories
              }
            />
          </div>

          <div className="row">
            <PhysicalExam title={"Examen Físico"} name={"physicalExam"} />
          </div>
          <div className="row">
            <Observations title={"Diagnóstico"} name={"diagnosis"} />
            <Observations
              title={"Tratamiento Médico"}
              name={"medicaltreatment"}
            />
          </div>
          <div className="row">
            <Observations title={"Plan de exámenes"} name={"plan"} />
            <Observations title={"Observaciones"} name={"observations"} />
          </div>
        </div>

        <div className="page-navs bg-white">
          <div className="nav-scroller">
            <div className="nav nav-tabs nav-line nav-color-secondary d-flex align-items-center justify-contents-center w-100">
              <div className="ml-auto" style={{ padding: 5 }}>
                <button
                  onClick={handleSubmit(onTest)}
                  className="btn"
                  style={{ backgroundColor: "#19769f" }}
                >
                  Guardar Ficha
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.check}>
          <Modal.Header>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Alerta
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            ¿Estas seguro que deseas cerrar la cita sin añadir un tratamiento y
            observaciones al paciente?
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => {
                  this.setState({ check: false });
                  onFormSubmit(this.state.data);
                }}
              >
                Si
              </button>
            </div>
            <div>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => {
                  this.setState({ check: false });
                }}
              >
                No
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    appointment: state.reducer.appointment,
    patient: state.reducer.patient,
    formulary: state.reducer.formulary,
    showNot: state.reducer.showNot,
    appointmentReason: getFormValues("AppointmentReason")(state),
    physicalExam: getFormValues("PhysicalExam")(state),
    observation: getFormValues("Observations")(state)
  };
};

const mapDispatchToProps = dispatch => {
  bindActionCreators({ setConsultation, showModal }, dispatch);
  return {
    initializeForm: (formName, values) =>
      dispatch(initializeForm(formName, values))
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "PatientEntry"
  })
)(PatientEntry);
