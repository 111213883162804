import React, { Component } from 'react';
import Header from '../../Header';
import Sidebar from '../../sidebar/Sidebar'
import UserGreet from '../../../consts/UserGreet'
import { Link } from "react-router-dom";



class AllAppointment extends Component {
  render() {
    return (
      <><Header/>
      <Sidebar/>
        <div className="main-panel">
          <div className="content">  
            <UserGreet />

            <div className="col-md-12" style={{paddingTop: "15px"}}>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">Todas las citas Hospital de Clínicas Caracas
                            <div className="ml-auto">
                                <Link  to="/doctorappointment"><button className="btn btn-success">Volver a citas</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div id="calendar">
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div></>
    );
  }
}

export default AllAppointment;
