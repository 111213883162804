import React from "react";
import { Field, reduxForm } from "redux-form";
import LoginRegister from "../../consts/LoginRegister";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchPasswordConfirm } from "../../actions/user_actions";
// import { register } from "../../actions/user_actions";
import "./style.css";
import Logo from "../../img/iconsGradient.png";

const validate = values => {
  const errors = {};

  if (!values.code) {
    errors.code = "Obligatorio";
  } else if (values.code.length > 6) {
    errors.code = "Datos incorrectos";
  }

  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input
          className={className}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        {touched && (error && <span>{error}</span>)}
      </div>
    </div>
  );
};

class InsertCode extends React.Component {
  render() {
    const { handleSubmit, emailForget } = this.props;
    const onFormSubmit = data => {
      let values = {
        ForgetPasswordConfirm: {
          email: emailForget,
          code: data.code
        }
      };
      this.props.dispatch(fetchPasswordConfirm(values));
    };

    return (
      <LoginRegister>
        <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
          <div className="container container-login container-transparent animated fadeIn">
            <div className="login-form">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                  marginTop: -50
                }}
              >
                <img width="200" height="200" src={Logo} alt="logo" />
              </div>

              <h1 className="text-center">Novem</h1>

              <div className="form-group">
                <label className="placeholder">
                  <b>Inserta el código</b>
                </label>
                <Field
                  className="form-control"
                  name="code"
                  component={renderField}
                  type="text"
                  placeholder="Código"
                />
              </div>
              <div>
                <button
                  className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
                  onClick={handleSubmit(onFormSubmit)}
                >
                  Validar
                </button>
              </div>
            </div>
          </div>
        </div>
      </LoginRegister>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    emailForget: state.reducer.emailForget
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchPasswordConfirm }, dispatch);

export default compose(
  reduxForm({
    form: "InsertCode",
    validate
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InsertCode);
