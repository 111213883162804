import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PanelHeader from "./PanelHeader";
import AppointmentModule from "./AppointmentModule";
import SuscripctionModule from "./suscriptionDetails";
import RatingModule from "./RatingModule";
import { listPatients } from "../actions/appointment_actions";

class Main extends Component {
  render() {
    return (
      <>
        <PanelHeader />

        <RatingModule />
        <div className="row" style={{ marginLeft: "0px" }}>
          <AppointmentModule />
        </div>

        <SuscripctionModule />
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ listPatients }, dispatch);

export default connect(null, mapDispatchToProps)(Main);
