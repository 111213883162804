import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addNonworkingday,
  deleteNonworkingday
} from "../../../actions/doctor_actions";
import { showModal } from "../../../actions/user_actions";

import { Modal } from "react-bootstrap";

import { withRouter } from "react-router-dom";

import moment from "moment";
import "moment/locale/es";
moment.locale("es");
/*import Header from "../../../components/Header";
import Sidebar from "../../../components/sidebar/Sidebar";
import UserGreet from "../../../consts/UserGreet";
import SessionId from "./SessionId";*/

const validate = values => {
  const errors = {};

  if (!values.clinic) {
    errors.clinic = "Obligatorio";
  }

  if (!values.start) {
    errors.start = "Obligatorio";
  }

  if (!values.end) {
    errors.end = "Obligatorio";
  } else if (values.end < values.start) {
    errors.end = "Fecha final no válida";
  }

  if (!values.quotas) {
    errors.quotas = "Obligatorio";
  }
  if (!values.type) {
    errors.type = "Obligatorio";
  }

  return errors;
};

const renderFieldSelect = ({
  input,
  name,
  className,
  type,
  options,
  children,
  onClick,
  meta: { touched, error }
}) => {
  return (
    <div>
      <select className={className} {...input} onClick={onClick}>
        {children}
      </select>

      {touched && (error && <span>{error}</span>)}
    </div>
  );
};

const type = [
  {
    _id: "0",
    es: {
      name: "Reposo"
    },
    en: {
      name: "Repose"
    }
  },
  {
    _id: "1",
    es: {
      name: "Vacaciones"
    },
    en: {
      name: "Holidays"
    }
  },
  {
    _id: "2",
    es: {
      name: "Congreso"
    },
    en: {
      name: "congress"
    }
  }
];

const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  min,
  onClick,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input
          className={className}
          {...input}
          type={type}
          placeholder={placeholder}
          onClick={onClick}
          min={min}
        />
        {touched && (error && <span>{error}</span>)}
      </div>
    </div>
  );
};

class Holidays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      send: true,
      vacations: this.props.user.medical.vacations,
      delete: false,
      item: ""
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ modal: false });
  }

  render() {
    const { user, handleSubmit, maxDate } = this.props;
    const { vacations } = this.state;

    const onFormSubmit = data => {
      let values = {
        vacation: {
          office_id: data.clinic === "Todas" ? "" : data.clinic,
          start_date: data.start,
          end_date: data.end,
          status: "programmed",
          type_period: data.type
        }
      };

      this.props.addNonworkingday(values);
      this.handleClose();
      this.props.reset();
    };

    return (
      <>
        <div className="card">
          <div className="card-header">
            <button
              className="btn"
              style={{
                backgroundColor: "#19769F",
                color: "#ffffff",
                float: "right"
              }}
              onClick={() => {
                if (user.medical.offices.length === 0) {
                  this.props.dispatch(
                    showModal({
                      open: true,
                      message: "Debe agregar sus estudios primero"
                    })
                  );
                } else this.setState({ modal: true });
              }}
            >
              Agregar
            </button>
            <div className="d-flex align-items-center">
              <h4
                style={{
                  fontFamily: "Poppins",
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Período
              </h4>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <table
                id="add-row"
                className="display table table-striped table-hover"
              >
                <thead>
                  <tr>
                    <th>Tipo de período</th>
                    <th>Consultorio</th>
                    <th>Inicio</th>
                    <th>Fin</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {vacations.length > 0 &&
                    vacations.map((item, i) => {
                      if (!item.deleted_at)
                        return (
                          <tr key={i}>
                            <td>{item.type_period}</td>
                            <td>
                              {user.medical.offices.map((test, i) => {
                                let name = "";
                                if (item.office_id === test._id) {
                                  name = test.name;
                                }
                                return name;
                              })}
                            </td>
                            <td>
                              {moment(item.start_date).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              {moment(item.end_date).format("DD/MM/YYYY")}
                            </td>

                            <td>
                              <div className="form-button-action">
                                {/* <button
                            type="button"
                            data-target="#addRowModal"
                            data-toggle="tooltip"
                            title=""
                            className="btn btn-link btn-primary btn-lg"
                            data-original-title="Editar"
                          >
                            <i className="fa fa-edit"></i>
                          </button>*/}
                                <button
                                  onClick={() => {
                                    this.setState({
                                      delete: true,
                                      item: item
                                    });
                                  }}
                                  className="btn btn-link btn-danger"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      else return null;
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal size="lg" show={this.state.modal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Nuevo período no laborable
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Consultorio</label>
                    <div className="select2-input">
                      <Field
                        name="clinic"
                        component={renderFieldSelect}
                        className="form-control"
                      >
                        <option></option>
                        {user.medical &&
                          user.medical.offices.length > 0 &&
                          user.medical.offices.map((item, i) => (
                            <option key={i} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        <option value={"Todas"}>Todas</option>
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Inicio</label>
                    <Field
                      name="start"
                      className="form-control"
                      component={renderField}
                      type="date"
                      min={moment().format("YYYY-MM-DD")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Fin</label>
                    <Field
                      name="end"
                      className="form-control"
                      component={renderField}
                      type="date"
                      min={maxDate}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Tipo de Período</label>
                    <div className="select2-input">
                      <Field
                        name="type"
                        component={renderFieldSelect}
                        className="form-control"
                      >
                        <option></option>
                        {type.map((item, i) => (
                          <option key={i} value={item.es.name}>
                            {item.es.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              style={{ backgroundColor: "#19769F" }}
              onClick={handleSubmit(onFormSubmit)}
            >
              Guardar
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.delete}>
          <Modal.Header>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Eliminar
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            ¿Seguro que deseas eliminar este período de{" "}
            {this.state.item.type_period}?
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => this.setState({ delete: false, item: "" })}
              >
                Cancelar
              </button>
            </div>
            <div>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => {
                  this.props.deleteNonworkingday(this.state.item);

                  this.setState({ delete: false, item: "" });
                }}
              >
                Aceptar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector("Holidays");

  return {
    user: state.reducer.user,
    maxDate: selector(state, "start")
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { addNonworkingday, deleteNonworkingday, showModal },
    dispatch
  );

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "Holidays",
    validate
  })
)(Holidays);
