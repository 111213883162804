import React, { useEffect, useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Modal } from "react-bootstrap";

import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../store/browserHistory";

import { securityCeate, sendValidation } from "../../actions/user_actions";
//import history from "../../store/browserHistory";
import "../login/style.css";
import { useAlert } from "react-alert";
const validate = values => {
  const errors = {};

  if (!values.newPasswordSecurity) {
    errors.newPasswordSecurity = "Obligatorio";
  } else if (values.newPasswordSecurity.length !== 4) {
    errors.newPasswordSecurity = "Debe tener 4 caracteres";
  } else if (!/^[a-zA-ZñÑ10-9]+$/.test(values.newPasswordSecurity)) {
    errors.newPasswordSecurity = "Caracteres no validos";
  }

  if (!values.rePasswordSecurity) {
    errors.rePasswordSecurity = "Obligatorio";
  } else if (values.rePasswordSecurity.length !== 4) {
    errors.rePasswordSecurity = "Debe tener 4 caracteres";
  } else if (!/^[a-zA-ZñÑ10-9]+$/.test(values.rePassword)) {
    errors.rePasswordSecurity = "Caracteres no validos";
  } else if (values.rePasswordSecurity !== values.newPasswordSecurity) {
    errors.rePasswordSecurity = "No coinciden la contraseña";
  }

  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          className={className}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

const NewPassword = ({
  handleSubmit,
  tokenNewPassword,
  clickHandler,
  securityCeate,
  sendValidation,
  error,
  user
}) => {
  const alert = useAlert();

  const [show_modal, setShowModal] = useState(false);

  useEffect(() => {
    sendValidation();

    if (user.phones.length === 0) {
      setShowModal(true);
    }
  }, [sendValidation, user, user.user]);

  const onFormSubmit = data => {
    let values = {
      password: data.newPasswordSecurity,
      code: data.codeSecurity
    };
    //console.log("aca   antes", values);
    return securityCeate(values).then(value => {
      //  console.log("aca es true excelente todo", value);
      try {
        if (value.status === 200) {
          clickHandler(false);
          alert.success(value.msg);
        } else {
          throw new SubmissionError({
            _error: value.msg
          });
        }
      } catch (e) {}
    });
  };

  return (
    <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white m-auto">
      <div className="container container-login container-transparent animated fadeIn">
        <h4 className="text-center">
          Usted ha recibido una clave de identificación temporal en el teléfono
          celular registrado o al correo electrónico. Introduzca la clave
          recibida y la nueva clave de seguridad
        </h4>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="login-form">
            <div className="form-group">
              <label className="placeholder">
                <b>Código</b>
              </label>
              <Field
                className="form-control"
                name="codeSecurity"
                component={renderField}
                type="text"
                placeholder="Código"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
              />
            </div>

            <div className="form-group">
              <label className="placeholder">
                <b>Nueva contraseña</b>
              </label>
              <Field
                className="form-control"
                name="newPasswordSecurity"
                component={renderField}
                type="password"
                placeholder="Contraseña"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
              />
            </div>

            <div className="form-group">
              <label className="placeholder">
                <b>Repetir contraseña</b>
              </label>
              <Field
                className="form-control"
                name="rePasswordSecurity"
                component={renderField}
                type="password"
                placeholder="Repetir Contraseña"
              />
            </div>
            <div className="text-center my-2">
              {error && <strong>{error}</strong>}
            </div>

            <div>
              <button
                className="btn btn-primary col-md-5 float-left mt-3 mt-sm-0 fw-bold"
                type="button"
                onClick={() => clickHandler(false)}
              >
                Volver
              </button>

              <button
                className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
                type="submit"
              >
                Cambiar
              </button>
            </div>
          </div>
        </form>
      </div>
      <Modal show={show_modal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4
              style={{
                color: "#19769f",
                fontWeight: "bold"
              }}
            >
              Alerta
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5>
            {
              "Le invitamos ha agregar su número telefónico, para que obtenga el código de confirmación de su PIN de seguridad."
            }
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{ backgroundColor: "#35D8A6" }}
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
          <button
            className="btn"
            style={{ backgroundColor: "#19769f" }}
            onClick={() => {
              setShowModal(false);
              history.push("/editprofile");
            }}
          >
            Ir
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

/*
class NewPassword extends React.Component {
  componentDidMount() {
    console.log("entre aca componendimoun securit");

    this.props.dispatch(sendValidation());
  }
  render() {
    const { handleSubmit, tokenNewPassword } = this.props;
    const onFormSubmit = data => {
      let values = {
        password: data.newPasswordSecurity,
        code: data.codeSecurity
      };

      return this.props.dispatch(securityCeate(values)).then(value => {
        console.log("aca es true excelente todo", value);

        throw new SubmissionError({
          _error: value.msg
        });
      });
    };

    return (
      <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white m-auto">
        <div className="container container-login container-transparent animated fadeIn">
          <h4 className="text-center">
            Usted ha recibido una clave de identificación temporal en el
            teléfono celular registrado o al correo electrónico. Introduzca la
            clave recibida y la nueva clave de seguridad
          </h4>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="login-form">
              <div className="form-group">
                <label className="placeholder">
                  <b>Código</b>
                </label>
                <Field
                  className="form-control"
                  name="codeSecurity"
                  component={renderField}
                  type="text"
                  placeholder="Código"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                />
              </div>

              <div className="form-group">
                <label className="placeholder">
                  <b>Nueva contraseña</b>
                </label>
                <Field
                  className="form-control"
                  name="newPasswordSecurity"
                  component={renderField}
                  type="password"
                  placeholder="Contraseña"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                />
              </div>

              <div className="form-group">
                <label className="placeholder">
                  <b>Repetir contraseña</b>
                </label>
                <Field
                  className="form-control"
                  name="rePasswordSecurity"
                  component={renderField}
                  type="password"
                  placeholder="Repetir Contraseña"
                />
              </div>
              <div className="text-center my-2">
                {this.props.error && <strong>{this.props.error}</strong>}
              </div>

              <div>
                <button
                  className="btn btn-primary col-md-5 float-left mt-3 mt-sm-0 fw-bold"
                  type="button"
                  onClick={() => this.props.clickHandler(false)}
                >
                  Volver
                </button>

                <button
                  className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
                  type="submit"
                >
                  Cambiar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}*/

const mapStateToProps = (state, ownProps) => {
  return {
    tokenNewPassword: state.reducer.tokenNewPassword,
    user: state.reducer.user.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ securityCeate, sendValidation }, dispatch);

export default compose(
  reduxForm({
    form: "NewPassword",
    validate
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewPassword);
