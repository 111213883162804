import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { getCalendar } from "../actions/doctor_actions";
import { showModal } from "../actions/user_actions";

const localizer = momentLocalizer(moment);

class MyCalendar extends React.Component {
  state = {
    Date: new Date(),
    events: []
  };

  static getDerivedStateFromProps(props, state) {
    let events = [];

    if (props.calendar !== state.events) {
      props.calendar.map(data => {
        events.push({
          id: data._id,
          title:
            data.quantity === 0
              ? data.office_name
              : `${data.office_name} cantidad de citas: ${data.quantity}.`,
          start: new Date(data.start),
          end: new Date(data.end),
          color: data.quantity === 0 ? "#16a16b" : "#19769F"
        });

        return data;
      });

      props.reserved &&
        props.reserved.data &&
        props.reserved.data.map(data => {
          data.patients.map(item => {
            events.push({
              id: data._id,
              title: `Cita reservada, paciente: ${item.user.name} ${item.user.lastname}`,
              start: new Date(moment(data.date)),
              end: new Date(moment(data.date)),
              color: "#9d74cf"
            });
            return item;
          });

          return data;
        });
      return { events: events };
    }

    return null;
  }

  render() {
    return (
      <Calendar
        onNavigate={(date, month, navigate) => {
          var Xmas95 = new Date(date);
          var monthDate = Xmas95.getMonth() + 1;
          var yearDate = Xmas95.getFullYear();

          this.setState({ events: [] });

          this.props.getCalendar({
            year: String(yearDate),
            month: String(monthDate)
          });
        }}
        onSelectEvent={event =>
          this.props.showModal({
            open: true,
            message: event.title
          })
        }
        localizer={localizer}
        events={this.state.events}
        startAccessor="start"
        endAccessor="end"
        min={this.state.Date}
        max={this.state.Date}
        views={["month"]}
        messages={{
          next: "sig",
          previous: "ant",
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día"
        }}
        eventPropGetter={event => ({
          style: {
            backgroundColor: event.color
          }
        })}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    calendar: state.reducer.calendar,
    reserved: state.reducer.reserved
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCalendar, showModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCalendar);
