import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import Autosuggest from "react-autosuggest";
import { termLibraryInit, term } from "../../actions/user_actions";

const getSuggestions = (value, data) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : data &&
        data.length > 0 &&
        data.filter(
          lang =>
            lang.name.es.toLowerCase().slice(0, inputLength) === inputValue
        );
};

const getSuggestionValue = suggestion => suggestion.name.es;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => <div>{suggestion.name.es}</div>;

class AutocompleteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      suggestions: []
    };
    props.dispatch(termLibraryInit("history"));
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      type: newValue
    });

    this.props.dispatch(term(newValue));
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.props.library_all_by_type)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "",
      value,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        className="form-control"
        onFocus={() => console.log("sali")}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    library_all_by_type: state.reducer.library_all_by_type
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ termLibraryInit, term }, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "PatientEntry"
  })
)(AutocompleteComponent);
