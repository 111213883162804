import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { listPatients } from "../../../actions/appointment_actions";

class Patient extends Component {
  render() {
    return (
      <div data-tut="reactour__action">
        <ul className="nav nav-primary">
          <li className="nav-item" id="patient">
            <Link className="link" to="/patient">
              <i className="fas fa-users"></i>
              <p>Pacientes</p>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ listPatients }, dispatch);

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "Patient"
  })
)(Patient);
