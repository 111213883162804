import React, { Component } from "react";
import { connect } from "react-redux";
import UserGreet from "../consts/UserGreet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import Calendar from "./Calendar";
import CertifiedOn from "../assets/svg/certifiedOn";
import CertifiedOff from "../assets/svg/certifiedOff";

class PanelHeader extends Component {
  state = {
    show: false
  };
  render() {
    const { user } = this.props;
    return (
      <>
        <UserGreet />
        <div className="page-inner mt--5">
          <div className="row">
            <div className="col-md-6" data-tut="reactour__logo">
              <div className="card full-height card card-primary bg-primary-gradient">
                <div className="card-body">
                  <div className="avatar-sm float-left mr-2">
                    {user && user.user && user.user.picture === "" ? (
                      <FontAwesomeIcon icon={faUserMd} size="3x" />
                    ) : (
                      <img
                        alt="imagen-doctor"
                        className="avatar-img rounded-circle"
                        src={user.user.picture}
                      />
                    )}
                  </div>
                  <h4 className="mb-1 fw-bold">Mis Datos</h4>
                  <div className="information">
                    <span>
                      Nombre: {user && user.user && user.user.name}{" "}
                      {user && user.user && user.user.lastname}
                    </span>
                    <br />
                    <span>
                      Especialidades:{" "}
                      {user &&
                        user.medical &&
                        user.medical.specialties &&
                        user.medical.specialties.length > 0 &&
                        user.medical.specialties.map((item, i) => {
                          let content = ",";
                          if (user.medical.specialties.length - 1 === i) {
                            content = ".";
                          }
                          return `${item.name.es}${content}${" "}`;
                        })}
                    </span>
                    <br />
                    <span>
                      Ubicación: {user && user.user && user.user.city} -{" "}
                      {user && user.user && user.user.country},{" "}
                      {user && user.user && user.user.address}
                    </span>
                    <br />
                    <span>
                      Centro de salud:{" "}
                      {user &&
                        user.medical.offices.length > 0 &&
                        user.medical.offices[0].name}
                    </span>
                    <br />
                  </div>
                  {user &&
                  (!user.medical.status ||
                    user.medical.status === "rejected" ||
                    user.medical.status === "unconfirmed") ? (
                    <CertifiedOff
                      style={{
                        width: "60px",
                        height: "60px",
                        position: "absolute",
                        top: 0,
                        right: 0
                      }}
                    />
                  ) : (
                    <CertifiedOn
                      style={{
                        width: "60px",
                        height: "60px",
                        position: "absolute",
                        top: 0,
                        right: 0
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6" data-tut="reactour__goTo">
              <div className="card calendar">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <div
                      className="card-title"
                      style={{
                        fontFamily: "Poppins",
                        color: "#19769f",
                        fontWeight: "bold"
                      }}
                    >
                      Calendario
                    </div>
                    <div className="ml-auto">
                      <div className="row">
                        <div className="col">
                          <div
                            style={{
                              width: 20,
                              height: 20,
                              borderRadius: 5,
                              backgroundColor: "#19769F"
                            }}
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          Citas confimadas
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div
                            style={{
                              width: 20,
                              height: 20,
                              borderRadius: 5,
                              backgroundColor: "#9d74cf"
                            }}
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          Citas reservadas
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div
                            style={{
                              width: 20,
                              height: 20,
                              borderRadius: 5,
                              backgroundColor: "#16a16b"
                            }}
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          Período no laborable
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body calendar">
                  <Calendar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};

export default connect(mapStateToProps)(PanelHeader);
