import React, { Component } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStart } from "@fortawesome/free-solid-svg-icons";

export default class RatingModule extends Component {
  render() {
    return (
      <div className="col-md-6 valorations" data-tut="reactour__copy">
        <div className="card full-height">
          <div className="card-header">
            <div className="card-head-row">
              <div className="card-title">
                <h4
                  style={{
                    fontFamily: "Poppins",
                    color: "#19769f",
                    fontWeight: "bold"
                  }}
                >
                  Valoraciones
                </h4>
              </div>
              {/* <div className="card-tools">
                <ul
                  className="nav nav-pills nav-secondary nav-pills-no-bd nav-sm"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-today"
                      data-toggle="pill"
                      href="#pills-today"
                      role="tab"
                      aria-selected="true"
                    >
                      Hoy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-week"
                      data-toggle="pill"
                      href="#pills-week"
                      role="tab"
                      aria-selected="false"
                    >
                      Semana
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-month"
                      data-toggle="pill"
                      href="#pills-month"
                      role="tab"
                      aria-selected="false"
                    >
                      Mes
                    </a>
                  </li>
                </ul>
              </div>*/}
            </div>
          </div>
          <div className="card-body text-center">
            {/*<div className="d-flex">
              <div className="avatar">
                <span className="avatar-title rounded-circle border border-white bg-info">
                  J
                </span>
              </div>
              <div className="flex-1 ml-3 pt-1">
                <h6 className="text-uppercase fw-bold mb-1">
                  Joko Subianto <span className="text-warning pl-3">Media</span>
                </h6>
                <span className="text-muted">
                  I am facing some trouble with my viewport. When i start my
                </span>
              </div>
              <div className="rating">
                <input type="radio" name="rating" id="r1" />
                <label></label>

                <input type="radio" name="rating" id="r2" />
                <label></label>

                <input type="radio" name="rating" id="r3" />
                <label></label>

                <input type="radio" name="rating" id="r4" />
                <label></label>

                <input type="radio" name="rating" id="r5" />
                <label></label>
              </div>
            </div>*/}
            {/* <FontAwesomeIcon icon={faStart} size="8x" />
             */}
            <br />
            <h3 className="text-uppercase fw-bold mb-1 text-center">
              Sin Valoraciones
            </h3>
            {/*<div className="separator-dashed"></div>*/}
          </div>
        </div>
      </div>
    );
  }
}
