import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import {
  listReserved,
  listConfirmed
} from "../../../actions/appointment_actions";
import { showModal } from "../../../actions/user_actions";

class Appointment extends Component {
  constructor(props) {
    super(props);
    /*this.props.dispatch(listReserved());
    this.props.dispatch(listConfirmed());*/
    this.goAppoinment = this.goAppoinment.bind(this);
  }

  goAppoinment() {
    const { user, history } = this.props;
    if (
      user.medical.offices.length === 0 ||
      user.medical.schedules.length === 0 ||
      user.medical.documents.length === 0 ||
      user.medical.specialties.length === 0
    ) {
      this.props.dispatch(
        showModal({
          open: true,
          message: "Completa tu Perfil médico antes de continuar"
        })
      );
      history.push("/dashboard");
    } else {
      history.push("/doctorappointment");
    }
  }

  changeRoutePath(e) {
    e.preventDefault();
    this.props.dispatch(listReserved());
    const { user, history } = this.props;
    if (
      user.medical.offices.length === 0 ||
      user.medical.schedules.length === 0 ||
      user.medical.documents.length === 0 ||
      user.medical.specialties.length === 0
    ) {
      this.props.dispatch(
        showModal({
          open: true,
          message: "Debes completar su perfil médico"
        })
      );
      history.push("/dashboard");
    } else {
      history.push("/doctorappointment");
    }
  }

  render() {
    return (
      <div data-tut="reactour__scroll">
        <ul className="nav nav-primary">
          <li className="nav-item" id="appointment">
            <Link
              to={"/doctorappointment"}
              onClick={e => this.changeRoutePath(e)}
            >
              <i className="fas fa-notes-medical"></i>
              <p>Citas</p>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ listReserved, showModal, listConfirmed }, dispatch);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "Appointment"
  })
)(Appointment);
