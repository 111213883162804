import React from "react";
import { Field, reduxForm } from "redux-form";
import LoginRegister from "../../consts/LoginRegister";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchChangePassword, showModal } from "../../actions/user_actions";
import history from "../../store/browserHistory";
import "./style.css";

import Logo from "../../img/iconsGradient.png";

const validate = values => {
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = "Obligatorio";
  } else if (values.newPassword.length < 6) {
    errors.newPassword = "Debe tener 6 caracteres o mas";
  } else if (!/^[a-zA-ZñÑ10-9]+$/.test(values.newPassword)) {
    errors.newPassword = "Caracteres no validos";
  }

  if (!values.rePassword) {
    errors.rePassword = "Obligatorio";
  } else if (values.rePassword.length < 6) {
    errors.rePassword = "Debe tener 6 caracteres o mas";
  } else if (!/^[a-zA-ZñÑ10-9]+$/.test(values.rePassword)) {
    errors.rePassword = "Caracteres no validos";
  } else if (values.rePassword !== values.newPassword) {
    errors.rePassword = "No coinciden la contraseña";
  }

  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input
          className={className}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        {touched && (error && <span>{error}</span>)}
      </div>
    </div>
  );
};

class NewPassword extends React.Component {
  render() {
    const { handleSubmit, tokenNewPassword } = this.props;
    const onFormSubmit = data => {
      if (tokenNewPassword.length === 0) {
        this.props.dispatch(
          showModal({
            open: true,
            message:
              "No tienes el token para realizar esta operación. Debes realizar el proceso nuevamente"
          })
        );
        history.push("/LostPassword");
      } else {
        let values = {
          PasswordChange: {
            password: data.newPassword,
            rePassword: data.rePassword
          }
        };

        this.props.dispatch(fetchChangePassword(values, tokenNewPassword));
      }
    };

    return (
      <LoginRegister>
        <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
          <div className="container container-login container-transparent animated fadeIn">
            <div className="login-form">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                  marginTop: -50
                }}
              >
                <img width="200" height="200" src={Logo} alt="logo" />
              </div>

              <h1 className="text-center">Novem</h1>

              <div className="form-group">
                <label className="placeholder">
                  <b>Nueva contraseña</b>
                </label>
                <Field
                  className="form-control"
                  name="newPassword"
                  component={renderField}
                  type="password"
                  placeholder="Contraseña"
                />
              </div>

              <div className="form-group">
                <label className="placeholder">
                  <b>Repetir contraseña</b>
                </label>
                <Field
                  className="form-control"
                  name="rePassword"
                  component={renderField}
                  type="password"
                  placeholder="Repetir Contraseña"
                />
              </div>
              <div>
                <button
                  className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
                  onClick={handleSubmit(onFormSubmit)}
                >
                  Cambiar
                </button>
              </div>
            </div>
          </div>
        </div>
      </LoginRegister>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tokenNewPassword: state.reducer.tokenNewPassword
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchChangePassword, showModal }, dispatch);

export default compose(
  reduxForm({
    form: "NewPassword",
    validate
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewPassword);
