import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";

import { Modal, Button } from "react-bootstrap";
import {
  appointmentConfirm,
  appointmentReject
} from "../../../actions/appointment_actions";

moment.locale("es");

class UserAppointment extends Component {
  state = {
    modal: false
  };
  handleSubmit(data, item) {
    let values = {
      appointment_id: item._id,
      patient_id: data._id
    };

    this.props.appointmentConfirm(values);
  }

  handleReject(reas, data, item) {
    let values = {
      appointment_id: item._id,
      patient_id: data._id,
      observation: reas.reason
    };

    this.props.appointmentReject(values);
    this.setState({ modal: false });
  }

  render() {
    const { data, handleSubmit, item } = this.props;

    const handleClose = () => this.setState({ modal: false });
    const handleShow = () => this.setState({ modal: true });

    return (
      <div className="col-md-3 user-appointment">
        <div className="card card-info card-annoucement card-round">
          <div className="card-body text-center">
            <div className="card-opening">
              {data.user.name} {data.user.lastname}
            </div>
            <div className="card-desc">
              Fecha: {moment(item.date).format("DD/MM/YYYY")} <br />
              Hora: {moment(`${item.date} ${data.hour}`).format("HH:mm a")}{" "}
              <br />
              Tipo de Cita: {data.type_appointment}
            </div>
            <div className="card-detail">
              <button
                className="btn btn-light btn-rounded"
                onClick={() => this.handleSubmit(data, item)}
              >
                Aceptar
              </button>{" "}
              <button
                className="btn btn-light btn-rounded"
                onClick={handleShow}
              >
                Rechazar
              </button>
            </div>
          </div>
        </div>

        <Modal show={this.state.modal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h5
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Motivo de rechazo
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name={"reason"}
              className="form-control"
              component="textarea"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                backgroundColor: "#19769F",
                color: "#ffffff"
              }}
              onClick={handleSubmit(values =>
                this.handleReject(values, data, item)
              )}
            >
              Enviar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ appointmentConfirm, appointmentReject }, dispatch);

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  reduxForm({
    form: "UserAppointment"
  })
)(UserAppointment);
