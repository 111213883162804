import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class AppointmentReason extends Component {
  render() {
    return (
      <div className="col-md-6">
        <div className="card full-height">
          <div className="card-header">
            <div className="card-title">Motivo de Cita</div>
          </div>
          <div className="card-body">
            <div className="d-flex">
              <div className="flex-1 ml-3 pt-1">
                <Field
                  name="reason"
                  className="form-control"
                  component="textarea"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(
  withRouter,
  connect(),
  reduxForm({
    form: "AppointmentReason"
  })
)(AppointmentReason);
