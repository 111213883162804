import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Modal } from "react-bootstrap";
import { pdfConsultation } from "../../actions/appointment_actions";
import { showModal } from "../../actions/user_actions";

class Alert extends Component {
  render() {
    const {
      modal,
      message,
      pdf,
      showModal,
      pdfConsultation,
      appointment
    } = this.props;
    const handleClose = () => showModal(false);
    return (
      <Modal show={modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4
              style={{
                color: "#19769f",
                fontWeight: "bold"
              }}
            >
              Alerta
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5>{message}</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{ backgroundColor: "#35D8A6" }}
            onClick={handleClose}
          >
            Cerrar
          </button>
          {pdf && (
            <button
              className="btn"
              style={{ backgroundColor: "#19769F" }}
              onClick={() => {
                showModal(false);
                pdfConsultation({
                  appointment_id: pdf,
                  user_id: appointment._id
                });
              }}
            >
              Ver pdf
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    modal: state.reducer.modal,
    message: state.reducer.message,
    pdf: state.reducer.pdf,
    appointment: state.reducer.appointment
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showModal, pdfConsultation }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);
