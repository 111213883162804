import React, { useState } from "react";
import { css } from "@emotion/core";
// First way to import
import { GridLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
`;

const Loading = () => {
  const [loading] = useState(true);
  return (
    <div
      style={{
        padding: 25
      }}
    >
      <GridLoader css={override} color={"#19769F"} loading={loading} />
    </div>
  );
};

export default Loading;
