import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  listSuscriptions,
  transferInit
} from "../../actions/suscription_actions";
import Loading from "../Loading";
import PaySuscription from "./PaySuscription";

const Suscription = ({
  user,
  listSuscriptions,
  transferInit,
  success,
  initSuscription,
  error,
  loading
}) => {
  const [list, setlist] = useState([]);
  const [item, setitem] = useState([]);
  const [view, setView] = useState(false);

  const [limit, setlimit] = useState(0);
  useEffect(() => {
    if (!success && list.length === 0) listSuscriptions();
    else setlist(initSuscription);
  }, [success, listSuscriptions, initSuscription, list.length]);

  return (
    <div className="justify-content-center">
      <h4
        className="page-title justify-content-center"
        style={{ display: "flex", marginTop: 10 }}
      >
        Suscripción
      </h4>
      {loading && !view && <Loading />}
      <div className="row justify-content-center">
        {!view &&
          list.map((suscription, index) => {
            let limitMap;
            if (
              user.medical.suscription &&
              user.medical.suscription.name === suscription.name &&
              index !== limit
            ) {
              console.log("cambie limit", index);
              setlimit(index);
              limitMap = index;
            } else {
              limitMap = limit;
            }

            console.log("limit es map", limitMap);
            console.log("limit es", limit);
            let class2 = "card-disable";
            let classButton = "btn-disable";
            if (limitMap >= index) {
              class2 = "card-success";
              classButton = "btn-success";
            }

            let value = list[0].features.length;
            let features = [...suscription.features];
            if (list[0].features.length !== suscription.features.length) {
              let newArray = [...list[0].features];

              newArray.splice(0, suscription.features.length);

              features = features.concat(newArray);
              console.log("son distintosel", newArray);
              console.log("son distintosel", features);
            } else {
              console.log("no, son distint");
            }
            console.log("value vale", value);

            return (
              <div className="col-md-3 pl-md-0" key={index}>
                <div className={`card-pricing2 ${class2}`}>
                  <div className="pricing-header">
                    {index === 0 ? (
                      <h2 className="fw-bold">{suscription.name}</h2>
                    ) : (
                      <h3 className="fw-bold">{suscription.name}</h3>
                    )}

                    <span className="sub-title">{suscription.description}</span>
                  </div>
                  <div className="price-value">
                    <div className="value">
                      <span className="currency">{suscription.cost.unit}</span>
                      <span className="amount">{suscription.cost.amount}</span>
                      <span className="month">/year</span>
                    </div>
                  </div>
                  <div
                    style={{
                      paddingTop: "50px"
                    }}
                  >
                    {features.map((feature, i) => {
                      let classValidateFeatures =
                        i < suscription.features.length ? "" : "disable";
                      let classValidateLimit =
                        limitMap >= index ? "" : "disableInactive";
                      return (
                        <div key={i}>
                          <ul
                            className="pricing-content "
                            style={{
                              padding: "0px",
                              margin: "0px"
                            }}
                          >
                            <p style={{ color: "black", textAlign: "center" }}>
                              {feature.type_feature === "office"
                                ? "Oficina"
                                : feature.type_feature === "certification"
                                ? "Certificación"
                                : feature.type_feature === "appointment"
                                ? "Citas"
                                : feature.type_feature === "support"
                                ? "Soporte"
                                : `${feature.type_feature
                                    .charAt(0)
                                    .toUpperCase()}${feature.type_feature.slice(
                                    1,
                                    feature.type_feature.length
                                  )}`}
                            </p>
                          </ul>
                          <ul
                            className="pricing-content"
                            style={{ paddingTop: "0px" }}
                          >
                            <li
                              className={`${classValidateFeatures} ${classValidateLimit}`}
                              /*className={feature.unlimited ? "" : "disable"}*/
                              /* className={

                                limitMap >= index ? "" : "disableInactive")
                    }*/
                            >
                              {feature.description}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>

                  <button
                    disabled={
                      user.medical.suscription &&
                      user.medical.suscription.name === suscription.name
                        ? true
                        : limitMap >= index
                        ? false
                        : true
                    }
                    className={`btn  btn-border btn-lg w-75 fw-bold mb-3 ${classButton}`}
                    onClick={() => {
                      let id = {
                        suscription_id: suscription._id
                      };
                      setView(true);
                      setitem(suscription);
                      transferInit(id, "paypal");
                      transferInit(id, "transfer");
                    }}
                  >
                    {user.medical.suscription &&
                    user.medical.suscription.name === suscription.name
                      ? "Activa"
                      : "Suscribirse"}
                  </button>
                </div>
              </div>
            );
          })}
        {view && (
          <PaySuscription suscription={item} back={() => setView(false)} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    success: state.suscription.success,
    loading: state.suscription.loading,
    error: state.suscription.error,
    initSuscription: state.suscription.initSuscription
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      listSuscriptions,
      transferInit
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Suscription);

/* <ul
  className="pricing-content"
  style={{ padding: "0px", margin: "0px", paddingTop: "50px" }}
>
  <p style={{ color: "black", textAlign: "center" }}>Citas</p>
</ul>
<ul className="pricing-content" style={{ paddingTop: "0px" }}>
  <li
    className={
      suscription.appointments.unlimited ? "" : "disable"
    }
  >
    Agendar citas
  </li>
</ul>
<ul
  className="pricing-content"
  style={{ padding: "0px", margin: "0px" }}
>
  <p style={{ color: "black", textAlign: "center" }}>
    Consultorio
  </p>
</ul>
<ul className="pricing-content" style={{ paddingTop: "0px" }}>
  <li
    className={
      suscription.offices.configuration > 0 ? "" : "disable"
    }
  >
    Configura tu consultorio
  </li>
  <li
    className={suscription.offices.consultation ? "" : "disable"}
  >
    Ingreso a consultas
  </li>
  <li
    className={suscription.offices.certification ? "" : "disable"}
  >
    Certifica tu consultorio
  </li>
</ul>
<ul
  className="pricing-content"
  style={{ padding: "0px", margin: "0px" }}
>
  <p style={{ color: "black", textAlign: "center" }}>Pacientes</p>
</ul>
<ul className="pricing-content" style={{ paddingTop: "0px" }}>
  <li
    className={
      suscription.patients.medical_record > 0 ||
      !suscription.patients.medical_record
        ? ""
        : "disable"
    }
  >
    {!suscription.patients.medical_record
      ? "Ilimitado"
      : suscription.patients.medical_record}{" "}
    Resguardo y acceso a expedientes
  </li>
</ul>

<ul
  className="pricing-content"
  style={{ padding: "0px", margin: "0px" }}
>
  <p style={{ color: "black", textAlign: "center" }}>MedicPlus</p>
</ul>
<ul className="pricing-content" style={{ paddingTop: "0px" }}>
  <li
    className={
      suscription.patients.medicplus.appointment_request > 0
        ? ""
        : "disable"
    }
  >
    Solicitud de citas desde el app
  </li>
  <li
    className={
      suscription.patients.medicplus.medical_headline > 0
        ? ""
        : "disable"
    }
  >
    Registro como médicode cabecera de usuario
  </li>
</ul>
<ul
  className="pricing-content"
  style={{ padding: "0px", margin: "0px" }}
>
  <p style={{ color: "black", textAlign: "center" }}>Soporte</p>
</ul>
<ul className="pricing-content" style={{ paddingTop: "0px" }}>
  <li className={suscription.soport.email ? "" : "disable"}>
    E-mail
  </li>
  <li className={suscription.soport.phone ? "" : "disable"}>
    Telefono
  </li>
  <li className={suscription.soport.backup ? "" : "disable"}>
    Respaldo
  </li>
</ul>*/
