import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import MainWork from "./mainwork";
import AppoinmentConfig from "./appoinmentconfig";
import Holidays from "./holidaysForm";

class Configuration extends Component {
  render() {
    return (
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <h4
              className="page-title"
              style={{
                fontFamily: "Poppins",
                color: "#19769f",
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              Datos laborales
            </h4>
            <MainWork />
            <h4
              className="page-title"
              style={{
                fontFamily: "Poppins",
                color: "#19769f",
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              Horario de atención presencial
            </h4>
            <AppoinmentConfig />
            <h4
              className="page-title"
              style={{
                fontFamily: "Poppins",
                color: "#19769f",
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              Períodos no laborales
            </h4>
            <Holidays />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(Configuration);
