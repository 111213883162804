import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { Modal } from "react-bootstrap";
import {
  updateAddressUser,
  updateBirthdateUser
} from "../../actions/emd_actions";
import moment from "moment";
import Loading from "../Loading";

import "moment/locale/es";
moment.locale("es");

const validate = values => {
  const errors = {};
  if (!values.country) {
    errors.country = "Obligatorio";
  }
  if (!values.city) {
    errors.city = "Obligatorio";
  }

  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  max,
  placeholder,
  meta: { touched, error }
}) => {
  return (
    <div>
      <label>{placeholder}</label>
      <input
        className={className}
        {...input}
        type={type}
        max={max}
        placeholder={placeholder}
      />
      {touched && (error && <span>{error}</span>)}
    </div>
  );
};

class PatientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      show: false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpenBirthdate = this.handleOpenBirthdate.bind(this);
    this.handleCloseBirthdate = this.handleCloseBirthdate.bind(this);
  }
  handleOpen() {
    this.setState({ modal: true });
  }
  handleClose() {
    this.setState({ modal: false });
  }

  handleOpenBirthdate() {
    this.setState({ show: true });
  }
  handleCloseBirthdate() {
    this.setState({ show: false });
  }

  render() {
    const { data, reset, handleSubmit, loading, loadingBirthdate } = this.props;

    const icon = data.gender === "Femenino" ? "icon-user-female" : "icon-user";

    const onFormSubmit = datos => {
      let values = {
        user_id: data._id,
        full_address: {
          country: datos.country,
          city: datos.city,
          address: datos.address
        }
      };

      this.props.updateAddressUser(values);
      this.handleClose();
      reset("PatientInfo");
    };

    const onBirthdate = datos => {
      let values = {
        birthdate: datos.birthdate,
        user_id: data._id
      };

      this.props.updateBirthdateUser(values);
      this.handleCloseBirthdate();
      reset("PatientInfo");
    };

    return (
      <>
        <div
          className="row row-card-no-pd"
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <div className="col-md-2">
            <div className="card card-stats card-round">
              <div className="card-body ">
                <div className="row">
                  <div className="col-auto">
                    <div className="icon-big text-center">
                      <i className={icon}></i>
                    </div>
                  </div>
                  <div className="col-7 col-stats">
                    <div className="numbers">
                      <p className="card-category">Nombre</p>
                      <h2 className="card-title">
                        {data.name} {data.lastname}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card card-stats card-round">
              <div className="card-body ">
                <div className="row">
                  <div className="col-auto">
                    <div className="icon-big text-center">
                      <i className="flaticon-like-1 text-danger"></i>
                    </div>
                  </div>
                  <div className="col-7 col-stats">
                    <div className="numbers">
                      <p className="card-category">Edad</p>
                      {loadingBirthdate && <Loading />}
                      {!loadingBirthdate && (
                        <>
                          {!data.age ? (
                            <button
                              onClick={this.handleOpenBirthdate}
                              className="btn btn-icon btn-round btn-primary text-center"
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          ) : (
                            <h4 className="card-title">{data.age} Años</h4>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card card-stats card-round">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    <div className="icon-big text-center">
                      <i className="fas fa-burn text-danger"></i>
                    </div>
                  </div>
                  <div className="col-7 col-stats">
                    <div className="numbers">
                      <p className="card-category">Tipo de Sangre</p>
                      <h4 className="card-title">
                        {data.Health && data.Health.typeBlood}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card card-stats card-round">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    <div className="icon-big text-center">
                      <i className="icon-graduation text-primary"></i>
                    </div>
                  </div>
                  <div className="col-7 col-stats">
                    <div className="numbers">
                      <p className="card-category">Profesión</p>
                      <h4 className="card-title">{data.ocupation}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card card-stats card-round">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    <div className="icon-big text-center">
                      <i className="fas fa-map-marker-alt text-danger"></i>
                    </div>
                  </div>
                  <div className="col-7 col-stats">
                    <div className="numbers">
                      <p className="card-category">Ubicación</p>
                      {loading && <Loading />}
                      {!loading && (
                        <h4 className="card-title">
                          {!data.city ? (
                            <button
                              onClick={this.handleOpen}
                              className="btn btn-icon btn-round btn-primary text-center"
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          ) : (
                            data.city
                          )}
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" show={this.state.modal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Agregar dirección
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Field
                    name="country"
                    className="form-control"
                    component={renderField}
                    type="input"
                    placeholder={"País"}
                  />
                </div>
                <div className="form-group">
                  <Field
                    name="city"
                    className="form-control"
                    component={renderField}
                    type="input"
                    placeholder={"Ciudad"}
                  />
                </div>
                <div className="form-group">
                  <Field
                    name="address"
                    className="form-control"
                    component={renderField}
                    type="input"
                    placeholder={"Dirección"}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              style={{ backgroundColor: "#19769F" }}
              onClick={handleSubmit(onFormSubmit)}
            >
              Guardar
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="md"
          show={this.state.show}
          onHide={this.handleCloseBirthdate}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Agregar fecha de nacimiento
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Field
                    name="birthdate"
                    className="form-control"
                    component={renderField}
                    max={moment().format("YYYY-MM-DD")}
                    type="date"
                    placeholder={"Fecha de nacimiento"}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              style={{ backgroundColor: "#19769F" }}
              onClick={handleSubmit(onBirthdate)}
            >
              Guardar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.reducer.loadingAdress,
    loadingBirthdate: state.reducer.loadingBirthdate
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateAddressUser, updateBirthdateUser }, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "PatientInfo",
    validate
  })
)(PatientInfo);
