import * as apiNovemApp from "../api/user/api_user";
import { showModal, loading } from "./user_actions";
import { getCalendar } from "./doctor_actions";
import history from "../store/browserHistory";

export const LIST_RESERVED_APPOINTMENT = "LIST_RESERVED_APPOINTMENT";
export const LIST_CONFIRMED_APPOINTMENT = "LIST_CONFIRMED_APPOINTMENT";
export const USER_APPOINTMENT = "USER_APPOINTMENT";
export const APPOINTMENT_ITEM = "APPOINTMENT_ITEM";
export const LIST_PATIENTS = "LIST_PATIENTS";
export const FIND_BY_DNI = "FIND_BY_DNI";
export const EMD_PATIENT = "EMD_PATIENT";
export const NEW_APPOINTMENT = "NEW_APPOINTMENT";
export const APPOINTMENT_USER = "APPOINTMENT_USER";
export const TYPE_FORMULARY = "TYPE_FORMULARY";
export const MEDICAL_CONSULTATION_PDF = "MEDICAL_CONSULTATION_PDF";
export const NOT_SHOW = "NOT_SHOW";
export const LOADING_FIND = "LOADING_FIND";
export const SUCCESS_FIND = "SUCCESS_FIND";
export const FAILURE_FIND = "FAILURE_FIND";
export const LOADING_PATIENT = "LOADING_PATIENT";

var Xmas95 = new Date();
var monthDate = Xmas95.getMonth() + 1;
var yearDate = Xmas95.getFullYear();

let msgError =
  "Se ha producido un error inesperado. inténtalo de nuevo más tarde.";

export const selectAppointment = data => {
  return { type: APPOINTMENT_USER, data };
};

export const loadingPatient = () => {
  return { type: LOADING_PATIENT };
};

export const listReserved = () => {
  let url = "/appointment/list-reserved";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    const request = apiNovemApp.requestToListAppointment(url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch({ type: LIST_RESERVED_APPOINTMENT, result });
        } else {
          alert(result.msg);
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const listConfirmed = () => {
  let url = "/appointment/list-confirmed";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToListAppointment(url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch({ type: LIST_CONFIRMED_APPOINTMENT, result });
        } else {
          alert(result.msg);
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const appointmentConfirm = values => {
  let url = "/appointment/confirm";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    const request = apiNovemApp.requestToUserAppointment(values, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(
            showModal({ open: true, message: "Cita aceptada correctamente" })
          );
          dispatch(listReserved());
          dispatch(listConfirmed());
          dispatch(
            getCalendar({
              year: String(yearDate),
              month: String(monthDate)
            })
          );
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const appointmentReject = values => {
  let url = "/appointment/reject";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserAppointment(values, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(
            showModal({ open: true, message: "Cita rechazada correctamente" })
          );
          dispatch(listReserved());
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const appointmentCheckIn = values => {
  let url = "/appointment/check-in";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserAppointment(values, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch({ type: USER_APPOINTMENT, result: result.data });
          dispatch({ type: APPOINTMENT_ITEM, result: values });

          history.push("/patiententry");
        } else {
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const pdfConsultation = (values, detail) => {
  let url = "/appointment/generate-pdf";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserAppointment(values, url, token);
    dispatch(loading(true));
    request.then(result => {
      dispatch({
        type: MEDICAL_CONSULTATION_PDF,
        data: false
      });
      try {
        dispatch(loading(false));

        if (result.status === 200) {
          window.open(result.data.url);
          dispatch(
            showModal({ open: true, message: "Pdf creado correctamente" })
          );
          if (!detail) {
            history.push("/doctorconsultation");
          }
        } else {
          dispatch(showModal({ open: true, message: "Intentar de nuevo" }));
          if (!detail) {
            history.push("/doctorconsultation");
          }
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const setConsultation = values => {
  let url = "/appointment/set-consultation";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    const request = apiNovemApp.requestToUserAppointment(values, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));

        if (result.status === 200) {
          dispatch(listConfirmed());
          dispatch({
            type: MEDICAL_CONSULTATION_PDF,
            data: values.appointment_id
          });
          dispatch(
            showModal({
              open: true,
              message: show
                ? "Su ficha a sido cerrada correctamente"
                : "Su cita a sido cerrada correctamente"
            })
          );
          show ? history.push("/patient") : history.push("/doctorconsultation");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
          //  history.push("/doctorconsultation");
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const listPatients = () => {
  let url = "/appointment/list-patients";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToListAppointment(url, token);
    dispatch(loadingPatient());
    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch({ type: LIST_PATIENTS, result: result.data });
          //History.push("/patient");
        } else {
          console.log("Error");
          // goTo.push("/");
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const loadingFind = () => {
  return { type: LOADING_FIND };
};
export const successFind = () => {
  return { type: SUCCESS_FIND };
};
export const failureFind = msg => {
  return { type: FAILURE_FIND, msg };
};

export const findByDni = values => {
  let url = "/patient/find-by-dni";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserAppointment(values, url, token);
    dispatch(loadingFind());
    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(successFind());
          if (!result.data) {
            dispatch(failureFind("No existe el usuario"));
          }
          dispatch({ type: FIND_BY_DNI, result: result.data });
          // goTo.push("/doctorconsultation");
        } else {
          dispatch(failureFind(result.msg));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
    return request;
  };
};

export const initConsultation = () => {
  let url = "/appointment/init-consultation";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToListAppointment(url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch({ type: TYPE_FORMULARY, result: result.data });
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
    return request;
  };
};

export const addAppoinment = values => {
  let url = "/appointment/add";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserAppointment(values, url, token);
    dispatch(loading(true));

    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(showModal({ open: true, message: result.msg }));
          dispatch({ type: NEW_APPOINTMENT, result: result.data });
          dispatch(listReserved());
          dispatch(listConfirmed());
          history.push("/doctorappointment");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
          // goTo.push("/");
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const getEMD = values => {
  let url = "/patient/get-emd";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserAppointment(values, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch({ type: USER_APPOINTMENT, result: result.data });
          dispatch({ type: EMD_PATIENT, data: result.data });
          history.push("/detailemd");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
          // goTo.push("/");
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const addPatient = values => {
  let url = "/patient/add";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(values, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch({ type: NOT_SHOW });
          dispatch(listPatients());
          dispatch({ type: USER_APPOINTMENT, result: result.data });
          dispatch({
            type: APPOINTMENT_ITEM,
            result: {
              appointment_id: result.data._id,
              patient_id: result.data._id
            }
          });
          history.push("/patientedit");
        } else {
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const addPatientBackup = values => {
  let url = "/appointment/backup-add";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(values, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(initConsultation());
          dispatch({ type: NOT_SHOW });
          dispatch(listPatients());
          dispatch({
            type: USER_APPOINTMENT,
            result: result.data.user
          });
          dispatch({
            type: APPOINTMENT_ITEM,
            result: {
              appointment_id: result.data.appointment_id,
              patient_id: result.data.patient_id
            }
          });
          history.push("/patientedit");
        } else {
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};
