import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppointmentDetail from "./AppointmentDetail";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

class AppointmentModule extends Component {
  render() {
    const { reserved } = this.props;

    return (
      <div className="col-md-6 appointment-module" data-tut="reactour__style">
        <div className="card full-height">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <div className="card-title">
                <h4
                  style={{
                    fontFamily: "Poppins",
                    color: "#19769f",
                    fontWeight: "bold"
                  }}
                >
                  Solicitudes de citas recibidas
                </h4>
              </div>
              <div className="ml-auto">
                <Link
                  to="/doctorappointment"
                  className="btn"
                  style={{
                    backgroundColor: "#19769F",
                    color: "#ffffff"
                  }}
                >
                  Revisar
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body">
            <ol className="activity-feed">
              {reserved && reserved.data && reserved.data.length === 0 ? (
                <h3 className="text-uppercase fw-bold mb-1 text-center">
                  Sin solicitudes
                </h3>
              ) : (
                <>
                  {reserved &&
                    reserved.data &&
                    reserved.data
                      .slice(0, 5)
                      .sort((a, b) => {
                        if (a.date > b.date) {
                          return 1;
                        }
                        if (a.date < b.date) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((item, i) => (
                        <AppointmentDetail data={item} key={i} />
                      ))}
                </>
              )}
            </ol>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    reserved: state.reducer.reserved
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(AppointmentModule);
