import React, { Component } from "react";

import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { css } from "@emotion/core";
// First way to import
import { GridLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
`;

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  render() {
    const { loading } = this.props;
    return (
      <Modal show={loading} size={"sm"} centered={true}>
        <div
          style={{
            padding: 25
          }}
        >
          <GridLoader
            css={override}
            color={"#19769F"}
            loading={this.state.loading}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.reducer.loading
  };
};

export default connect(mapStateToProps)(Loading);
