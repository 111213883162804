import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/es";
import { saveIMC } from "../../actions/emd_actions";
import CrudButtons from "../../consts/CrudButtons";
import Loading from "../Loading";

const validate = values => {
  const errors = {};

  if (values.height && !/^[0-9]+([,][0-9]+[0-9])?$/.test(values.height)) {
    errors.height = "Debe ser un número seguido de una coma y el decimal";
  }

  if (values.weight && values.weight.includes(".")) {
    errors.weight = "Reemplaza el . por una ,";
  } else if (values.weight && Number(values.weight) < 10) {
    errors.weight = "Lo siento, valor incorrecto";
  }

  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  disabled,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input className={className} {...input} type={type} />
        {touched && (error && <span>{error}</span>)}
      </div>
    </div>
  );
};

class IMC extends Component {
  state = {
    imc: [],
    count: 0,
    form: false
  };

  increment() {
    this.setState({ count: this.state.count + 3 });
  }

  decrement() {
    this.setState({ count: this.state.count - 3 });
  }

  test() {
    const { data } = this.props;
    if (data && data.height) {
      var test = [];
      for (var i = 0; i < data.height.length; i++) {
        test.push({
          height: data.height[i].value,
          weight: data.weight[i].value,
          imc: data.imc[i].Indicator,
          deleted_at: data.imc[i].deleted_at,
          date: data.imc[i].created_at,
          status: data.imc[i].status,
          verification: data.imc[i].verification
        });
      }
      this.setState({
        imc: test
      });
    }
  }
  componentDidMount() {
    this.test();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.height) {
      var test = [];
      for (var i = 0; i < props.data.height.length; i++) {
        test.push({
          height: props.data.height[i].value,
          weight: props.data.weight[i].value,
          imc: props.data.imc[i].Indicator,
          date: props.data.imc[i].created_at,
          deleted_at: props.data.imc[i].deleted_at,
          status: props.data.imc[i].status,
          verification: props.data.imc[i].verification
        });
      }
      if (props.data.height !== state.imc) {
        return { imc: test };
      }
    }

    return null;
  }

  render() {
    var finit = Math.round(this.state.imc.length / 3);
    /*if (!Number.isInteger(this.state.imc.length / 3)) finit = finit + 1;*/

    const { handleSubmit, user, dispatch, reset, loading } = this.props;
    const { imc } = this.state;

    const createTable = value => {
      let table = [];

      for (let i = 0; i < value; i++) {
        table.push(
          <li className="page-item" key={i}>
            <button
              className="page-link"
              style={{
                color: i * 3 === this.state.count ? "#ffffff" : "#19769F",
                backgroundColor: i * 3 === this.state.count ? "#19769F" : null
              }}
              onClick={() => this.setState({ count: i * 3 })}
            >
              {i + 1}
            </button>
          </li>
        );
      }
      return table;
    };

    const onFormSubmit = data => {
      let values = {
        user: user._id,
        weight: data.weight,
        height: data.height
      };

      dispatch(saveIMC(values));
      this.setState({ form: false });
      reset("IMC");
    };

    return (
      <div className="col-md-6">
        <div className="card full-height">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <div className="card-title">Estatura y peso</div>
              <div className="ml-auto">
                <button
                  onClick={() => {
                    this.setState({ form: !this.state.form });
                    reset("IMC");
                  }}
                  className="btn btn-icon btn-round btn-primary text-center"
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body  align-content-center justify-content-center">
            {this.state.form && (
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label>Talla</label>
                    <Field
                      name={"height"}
                      className="form-control"
                      component={renderField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label>Peso</label>
                    <Field
                      name={"weight"}
                      className="form-control"
                      component={renderField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="form-group">
                    <label>{/*Acción*/}</label>

                    <button
                      onClick={handleSubmit(onFormSubmit)}
                      className="btn btn-icon btn-round btn-primary text-center"
                    >
                      <i className="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {loading && <Loading />}
            {!loading && (
              <>
                {" "}
                <div className="separator-dashed"></div>{" "}
                {imc &&
                  imc

                    .sort(function(a, b) {
                      a = new Date(a.date);
                      b = new Date(b.date);
                      if (a > b) return -1;
                      if (a < b) return 1;
                      return 0;
                    })
                    .slice(this.state.count, this.state.count + 3)
                    .map((item, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex">
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                Talla
                              </h6>
                              <span className="text-muted">{item.height}</span>
                              <span className="text-warning pl-3"></span>
                            </div>
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                Peso
                              </h6>
                              <span className="text-muted">{item.weight}</span>
                              <span className="text-warning pl-3"></span>
                            </div>
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                Indicador
                              </h6>
                              <span style={{ color: item.imc.color }}>
                                {item.imc.name.es}
                              </span>
                              <span className="text-warning pl-3"></span>
                            </div>
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                Fecha
                              </h6>
                              <span className="text-muted">
                                {moment(item.date).format("DD-MM-YYYY")}
                              </span>
                            </div>
                            <CrudButtons
                              data={item}
                              position={i}
                              user_id={user._id}
                              clickHandler={"imc"}
                            />
                          </div>
                          <div className="separator-dashed"></div>
                        </div>
                      );
                    })}
                <br />
                <div className="align-content-center justify-content-center">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      {this.state.count > 0 ? (
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.decrement.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-left"></i>
                        </button>
                      ) : (
                        false
                      )}
                    </li>
                    {createTable(finit)}
                    {this.state.count + 3 < finit * 3 ? (
                      <li className="page-item">
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.increment.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-right"></i>
                        </button>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.reducer.loadingImc
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveIMC }, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "IMC",
    validate
  })
)(IMC);
