import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

export default class AppointmentDetail extends Component {
  render() {
    const { data } = this.props;

    return (
      <li className="feed-item feed-item-secondary">
        {" "}
        <span className="date" datespan="9-25">
          {moment(data.date).format("MMMM DD")}
        </span>
        <span className="text">
          {data.patients[0].user.name} {data.patients[0].user.lastname}
        </span>
        {"  "}{" "}
        <span className="text">
          {moment(`${data.date} ${data.patients[0].hour}`).format("HH:mm a")}
        </span>
      </li>
    );
  }
}
