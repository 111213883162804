/*import React, { useEffect, useState } from "react";
import Login from "./Login";
import { compose } from "redux";
import { connect } from "react-redux";
import LoginRegister from "../../consts/LoginRegister";

const index = ({ user }) => {
  const [state, setstate] = useState({});
  useEffect(() => {
    if (Object.entries(user).length !== Object.entries(state.user).length)
      setstate(user);
  }, user);
  return (
    <LoginRegister>
      <Login />
    </LoginRegister>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};

export default compose(connect(mapStateToProps))(index);*/

import React from "react";
import moment from "moment";
import Login from "./Login";
import LoginRegister from "../../consts/LoginRegister";
import "moment/locale/es";
moment.locale("es");

const Index = () => {
  return (
    <LoginRegister>
      <Login />
    </LoginRegister>
  );
};

export default Index;
