import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCalendar } from "../../../actions/doctor_actions";
import { withRouter } from "react-router-dom";
import history from "../../../store/browserHistory";

class MainMenu extends Component {
  constructor(props) {
    super(props);
    var Xmas95 = new Date();
    var monthDate = Xmas95.getMonth() + 1;
    var yearDate = Xmas95.getFullYear();

    this.props.getCalendar({
      year: String(yearDate),
      month: String(monthDate)
    });
  }

  componentDidMount() {
    if (history.location.pathname === "/dashboard") {
      document.getElementById("/dashboard").className += " active";
    }
  }

  render() {
    return (
      <div>
        <ul className="nav nav-primary">
          <li className="nav-item" id="/dashboard">
            <Link
              aria-expanded="true"
              className="link"
              to="/dashboard"
              onClick={() =>
                this.setState({
                  className: "nav-item active"
                })
              }
            >
              <i className="fas fa-home"></i>
              <p>Menú Principal</p>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCalendar }, dispatch);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MainMenu);
