import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Autosuggest from "react-autosuggest";

import {
  initSpecialty,
  addSpecialty,
  deleteSpecialty
} from "../../../actions/doctor_actions";
import { showModal } from "../../../actions/user_actions";
import { reduxForm } from "redux-form";
import { Modal } from "react-bootstrap";
import FileBase64 from "react-file-base64";

const getSuggestions = (value, data) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : data &&
        data.length > 0 &&
        data.filter(
          lang => lang.es.toLowerCase().slice(0, inputLength) === inputValue
        );
};

const getSuggestionValue = suggestion => suggestion.es;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => <div>{suggestion.es}</div>;

class Mainspecialty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: false,
      modal: false,
      send: true,
      delete: false,
      value: "",
      suggestions: [],
      item: "",
      specialty:
        this.props.user.medical.specialties &&
        this.props.user.medical.specialties.length > 0
          ? this.props.user.medical.specialties
          : []
    };
    this.props.dispatch(initSpecialty());
    this.handleClose = this.handleClose.bind(this);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      type: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.props.specialty)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getFiles(files) {
    this.setState({ files: files });
  }

  handleClose() {
    this.setState({ modal: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.specialty !== prevProps.user.medical.specialties) {
      this.setState({ specialty: prevProps.user.medical.specialties });
    }
  }

  render() {
    const { specialty, handleSubmit, user } = this.props;

    const { value, suggestions } = this.state;

    /*const handleClose = () => this.setState({ modal: false });*/

    const inputProps = {
      placeholder: "",
      value,
      onChange: this.onChange
    };

    const onFormSubmit = data => {
      let test = specialty.find(element => element.es === value);

      let values = {
        specialty: {
          name: test
            ? test
            : {
                _id: null,
                es: value,
                en: null
              }
        },
        certificate_64: this.state.files ? this.state.files.base64 : ""
      };
      console.log(values);

      this.props.dispatch(addSpecialty(values));
      this.handleClose();
      this.props.reset();
    };

    return (
      <div className="card">
        <div className="card-header">
          <button
            className="btn"
            style={{
              backgroundColor: "#19769F",
              color: "#ffffff",
              float: "right"
            }}
            onClick={() => {
              if (user.medical.documents.length === 0) {
                this.props.dispatch(
                  showModal({
                    open: true,
                    message: "Debe agregar sus estudios primero"
                  })
                );
              } else this.setState({ modal: true });
            }}
          >
            Agregar
          </button>
          <div className="card-title">
            <h4
              style={{
                fontFamily: "Poppins",
                color: "#19769f",
                fontWeight: "bold"
              }}
            >
              Especialidad
            </h4>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table
              id="add-row"
              className="display table table-striped table-hover"
            >
              <thead>
                <tr>
                  <th>Nombre de la especialidad</th>
                  <th>Estado</th>
                  <th style={{ width: "10%" }}>Acción</th>
                </tr>
              </thead>
              <tbody>
                {this.state.specialty &&
                  this.state.specialty.length > 0 &&
                  this.state.specialty.map((item, i) => (
                    <tr key={i}>
                      <td>{item.name.es}</td>
                      <td>
                        {item.status === "unconfirmed"
                          ? "Por confirmar"
                          : "Confirmado"}
                      </td>
                      <td>
                        <div className="form-button-action">
                          {/*<button
                        type="button"
                        data-target="#addRowModal"
                        data-toggle="tooltip"
                        title=""
                        className="btn btn-link btn-primary btn-lg"
                        data-original-title="Editar"
                      >
                        <i className="fa fa-edit"></i>
                      </button>*/}
                          <button
                            className="btn btn-link btn-danger"
                            onClick={() =>
                              this.setState({ delete: true, item: item })
                            }
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <Modal show={this.state.modal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Nueva especialidad
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body">
              <div className="form-group">
                <label>Elegir Especialidad </label>
                <div className="select2-input">
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                      this.onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequested
                    }
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    className="form-control"
                    onFocus={() => console.log("sali")}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Título</label>
                <FileBase64
                  className="form-control-file"
                  onDone={this.getFiles.bind(this)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn"
                style={{ backgroundColor: "#19769F" }}
                onClick={handleSubmit(onFormSubmit)}
              >
                Guardar
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.delete}>
          <Modal.Header>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Eliminar
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            ¿Seguro que deseas eliminar la especialidad{" "}
            {this.state.item && this.state.item.name.es}?
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => this.setState({ delete: false, item: "" })}
              >
                Cancelar
              </button>
            </div>
            <div>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => {
                  this.props.dispatch(deleteSpecialty(this.state.item));

                  this.setState({ delete: false, item: "" });
                }}
              >
                Aceptar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    specialty: state.reducer.specialty,
    user: state.reducer.user
  };
};

const mapDispatchToProps = dispatch => {
  bindActionCreators(
    { initSpecialty, addSpecialty, deleteSpecialty, showModal },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "Mainspecialty"
  })
)(Mainspecialty);
