import {
  SUCCESS_SUSCRIPTION,
  LOADING_SUSCRIPTION,
  FAILURE_SUSCRIPTION,
  INIT_SUSCRIPTION,
  INIT_TRANSFER,
  INIT_PAYPAL,
  INIT_STADISTIC
} from "../actions/suscription_actions";

const inicialState = {
  loading: false,
  error: false,
  success: false,
  msj: "",
  initSuscription: [],
  initTransfer: false,
  initPaypal: false,
  successStadistic: false,
  data: {}
};

export function suscription(state = inicialState, action) {
  try {
    switch (action.type) {
      case SUCCESS_SUSCRIPTION:
        return {
          ...state,
          success: true,
          loading: false,
          error: false
        };
      case LOADING_SUSCRIPTION:
        return {
          ...state,
          loading: true
        };
      case FAILURE_SUSCRIPTION:
        return {
          ...state,
          msj: action.msj,
          error: true,
          loading: false,
          success: false
        };
      case INIT_SUSCRIPTION:
        return {
          ...state,
          initSuscription: action.data
        };
      case INIT_TRANSFER:
        return {
          ...state,
          initTransfer: action.data
        };
      case INIT_PAYPAL:
        return {
          ...state,
          initPaypal: action.data
        };

      case INIT_STADISTIC:
        return {
          ...state,
          data: action.data,
          successStadistic: true
        };

      default:
        return state;
    }
  } catch (e) {
    console.log(e);
  }
}
