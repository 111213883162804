import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";

import UserAppointment from "./UserAppointment";
moment.locale("es");

class DoctorAppointment extends Component {
  render() {
    const { reserved } = this.props;

    return (
      <div className="page-inner">
        <h4
          className="page-title"
          style={{
            fontFamily: "Poppins",
            color: "#19769f",
            fontWeight: "bold",
            textAlign: "center"
          }}
        >
          Citas
        </h4>
        <div className="col-md-12" id="tab1">
          <div className="card full-height">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="card-title">
                  <h4
                    style={{
                      fontFamily: "Poppins",
                      color: "#19769f",
                      fontWeight: "bold"
                    }}
                  >
                    Listado de citas
                  </h4>
                </div>
                <div className="ml-auto">
                  <Link
                    to="/newappointment"
                    className="btn"
                    style={{
                      backgroundColor: "#19769F",
                      color: "#ffffff"
                    }}
                  >
                    Agendar una cita
                  </Link>
                  {/*<button

                                className="btn"
                                data-toggle="modal"
                                data-target="#addRowModal"
                                style={{ backgroundColor: "#19769F" }}
                              >
                                Agendar una cita
                              </button>*/}
                </div>
              </div>
            </div>
            {reserved && reserved.data && reserved.data.length === 0 ? (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <h4 className="text-center">Sin citas Solicitadas</h4>
              </div>
            ) : (
              <>
                {reserved &&
                  reserved.data &&
                  reserved.data
                    .sort((a, b) => {
                      if (a.date > b.date) {
                        return 1;
                      }
                      if (a.date < b.date) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((item, i) => {
                      return (
                        <div key={i}>
                          <div
                            className="row row-projects"
                            style={{
                              paddingTop: 10,
                              paddingBottom: 5,
                              paddingLeft: 10,
                              paddingRight: 10
                            }}
                          >
                            <div className="col-auto d-flex align-items-center justify-content-center">
                              <h5
                                style={{
                                  fontFamily: "Poppins",
                                  color: "#19769f",
                                  fontWeight: "bold"
                                }}
                              >
                                {moment(item.date).format("DD/MM/YYYY")}
                              </h5>
                            </div>
                            {item.patients.map(test => (
                              <UserAppointment
                                data={test}
                                item={item}
                                key={i}
                              />
                            ))}
                          </div>
                          <div
                            className="separator-dashed"
                            style={{ marginTop: -15, paddingHorizontal: 10 }}
                          ></div>
                        </div>
                      );
                    })}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    reserved: state.reducer.reserved
  };
};

export default compose(connect(mapStateToProps))(DoctorAppointment);
