import React from "react";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./sidebar/Sidebar";
import history from "../store/browserHistory";

class Container extends React.Component {
  /*componentDidMount() {
    console.log("entre aca ");
  }*/
  componentDidUpdate(nextProps) {
    var element = document.getElementById(nextProps.location.pathname);
    var elementNew = document.getElementById(history.location.pathname);

    if (element !== null) {
      if (nextProps.location.pathname !== history.location.pathname) {
        element.classList.remove("active");
      }
    } else if (
      nextProps.location.pathname === "/patiententry" ||
      (nextProps.location.pathname === "/doctorconsultation" &&
        document.getElementById("consultation"))
    ) {
      document.getElementById("consultation") &&
        document.getElementById("consultation").classList.remove("active");
    } else if (
      nextProps.location.pathname === "/detailemd" ||
      nextProps.location.pathname === "/patientedit" ||
      (nextProps.location.pathname === "/patient" &&
        document.getElementById("patient"))
    ) {
      document.getElementById("patient") &&
        document.getElementById("patient").classList.remove("active");
    } else if (
      nextProps.location.pathname === "/doctorappointment" ||
      (nextProps.location.pathname === "/newappointment" &&
        document.getElementById("appointment"))
    ) {
      document.getElementById("appointment") &&
        document.getElementById("appointment").classList.remove("active");
    }

    if (elementNew !== null) elementNew.className += " active";

    if (
      document.getElementById("consultation") &&
      (history.location.pathname === "/patiententry" ||
        history.location.pathname === "/doctorconsultation")
    ) {
      document.getElementById("consultation").className += " active";
    }

    if (
      document.getElementById("appointment") &&
      (history.location.pathname === "/doctorappointment" ||
        history.location.pathname === "/newappointment")
    ) {
      document.getElementById("appointment").className += " active";
    }

    if (
      document.getElementById("patient") &&
      (history.location.pathname === "/detailemd" ||
        history.location.pathname === "/patientedit" ||
        history.location.pathname === "/patient")
    ) {
      document.getElementById("patient").className += " active";
    }
  }

  render() {
    const { user, children, isLoading, isAuthenticated } = this.props;
    if (isLoading && user && Object.entries(user).length === 0) {
      /*  console.log(
        "entro aca en isLoading && user && Object.entries(user).length === 0"
      );*/
      return <div>Loading...</div>;
    } else {
      /*console.log(
        "nooooooooooo no entrisLoading && user && Object.entries(user).length === 0"
      );
      console.log("isLoading", isLoading);
      console.log("isLoading", user);
      console.log("isAuthenticated", isAuthenticated);*/
      if (!isAuthenticated && Object.entries(user).length === 0) {
        /*console.log("redireciono a login");*/
        return <Redirect to="/login" />;
      } else {
        /*console.log("aca estoy en true y true", isAuthenticated);
        console.log("aca estoy en true y true", user);*/
        if (Object.entries(user).length === 0) {
          return <Redirect to="/login" />;
        } else
          return (
            <div className="wrapper">
              <Header />
              <Sidebar />
              <div className="main-panel">
                <div className="content">{children}</div>
                <Footer />
              </div>
            </div>
          );
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    isLoading: state.reducer.isLoading,
    isAuthenticated: state.reducer.isAuthenticated
  };
};

export default compose(connect(mapStateToProps))(Container);
